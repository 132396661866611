import Navigation from '@/components/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import ClassJoin from './ClassJoin';
import ClassAddForm from './ClassAddForm';
import ClassSearch from './ClassSearch';
import { Route, Routes } from 'react-router';
import { Button, Container } from '@/components/common';
import styled from 'styled-components';
import LoadingPage from '@/layouts/LoadingPage';
import { clearFields } from '@/actions/joinClassStepper';
import { useEffect } from 'react';

const JoinClass = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  const groups = useSelector(s => s.school?.groups);
  const demoGroups = useSelector(s => s.school?.demoGroups);
  const userSchool = useSelector(s => s.school.currentSchool);
  const dispatch = useDispatch();

  // filter demo groups out of groups
  const demoGroupIds = demoGroups ? demoGroups.map(g => g.groupId) : [];
  const hasExistingGroups = groups
    ? !!groups.filter(g => !demoGroupIds.includes(g.groupId)).length
    : false;

  useEffect(() => {
    dispatch(clearFields());
  }, [dispatch]);

  if (!schoolId) {
    return <LoadingPage />;
  }

  return (
    <>
      <Navigation
        top
        rightComponent={
          <>
            <Button to="/account" color="link">
              Switch school
            </Button>
            {hasExistingGroups ? (
              <Button to="/" color="link">
                Exit
              </Button>
            ) : (
              <Button to="/logout" color="link">
                Logout
              </Button>
            )}
          </>
        }
      />
      <Body>
        <Container className="container-xs">
          <Routes>
            <Route path="/" element={<ClassSearch userSchool={userSchool} />} />
            <Route path="/join" element={<ClassJoin />} />
            <Route path="/new" element={<ClassAddForm />} />
          </Routes>
        </Container>
      </Body>
    </>
  );
};

const Body = styled.div`
  min-height: 100vh;
  padding-top: 9rem;
`;

export default JoinClass;
