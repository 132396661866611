import appReducer from './appReducer';
import localForage from 'localforage';
import analytics from '../analytics';
import client from '../config/apolloClient';
import { removeAuthToken } from '@/config/settings';

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    localForage.clear();
    sessionStorage.clear();
    removeAuthToken();
    window.Intercom && window.Intercom('shutdown');
    if (window.Confirmic) {
      window.Confirmic('ConsentManager:hide');
    }
    client.clearStore();
    analytics.shutdown();
  }

  return appReducer(state, action);
};

export default rootReducer;
