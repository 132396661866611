import React, { useCallback, useState } from 'react';
import analytics from '@/analytics';
import {
  Loading,
  toaster,
  Container,
  Header,
  Page,
  Form,
  Select,
  Button,
  Row,
  Col,
  Label
} from '@/components/common';
import { useSelector } from 'react-redux';
import { Api, useApiQuery } from '@/api';
import useLoadUserContext from '@/hooks/useLoadUserContext';
import moment from 'moment';

const AssignmentSettings = () => {
  const { loadUserContext } = useLoadUserContext();
  const schoolId = useSelector(s => s.school.schoolId);
  const { groupId, code } = useSelector(s => s.group);
  const [fields, setFields] = useState({});
  const [updatePending, setUpdatePending] = useState(false);

  const { loading } = useApiQuery(async () => {
    const data = await Api.getGroup(groupId);
    setFields(data);
    return;
  }, [groupId]);

  const handleFieldChange = (name, value) => {
    setFields({
      ...fields,
      [name]: value
    });
  };

  const handleSave = useCallback(async () => {
    const { weekStartDate } = fields;

    setUpdatePending(true);

    try {
      // This API should actually be a PATCH as it allows
      // partial updates to `studentIds` and `teacherIds`.
      await Api.updateGroup(groupId, {
        weekStartDate
      });

      await loadUserContext(schoolId);

      analytics.track('Class Card Updated', { category: 'Class' });
      toaster.success('Your assignment settings has been saved');
    } catch (e) {
      toaster.danger('Something went wrong', e);
    } finally {
      setUpdatePending(false);
    }
  }, [fields, loadUserContext, groupId, schoolId]);

  const getFirstWeekdaysOfLastSeptember = () => {
    // Get the current year and subtract 1 if the current month is before September
    const year = moment().month() < 8 ? moment().year() - 1 : moment().year();

    // Get the first day of last September
    const firstDayOfSeptember = moment([year, 8, 1]);

    // Find the first Monday of September
    const firstMondayOffset = (1 - firstDayOfSeptember.day() + 7) % 7;
    const firstMondayOfSeptember = firstDayOfSeptember
      .clone()
      .add(firstMondayOffset, 'days');

    // Generate dates for the first week (Monday to Friday)
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      const date = firstMondayOfSeptember.clone().add(i, 'days');
      weekDays.push({
        value: date.format('YYYY-MM-DD'),
        label: date.format('dddd') // 'dddd' gives the full name of the day
      });
    }

    return weekDays;
  };

  if (loading) {
    return <Loading />;
  }

  const weekStartDateOptions = getFirstWeekdaysOfLastSeptember();

  const groupWeekStartDate = moment(fields?.weekStartDate).format('YYYY-MM-DD');
  const weekStartDateValue =
    weekStartDateOptions.find(option => option.value === groupWeekStartDate) ??
    weekStartDateOptions[0];

  const weekEndDateValue = weekStartDateValue
    ? moment(weekStartDateValue.value).add(6, 'days').format('dddd')
    : null;

  return (
    <>
      <Header>
        <Header.Title>
          <h1>Assignment Settings</h1>
        </Header.Title>
      </Header>
      <Page.Body>
        <Container className="container-sm ml-0">
          <Form.Group>
            <Row>
              <Col md="4">
                <Label className="d-block text-lg mb-0">Start day</Label>
                <span className="text-muted">
                  Choose the day {code}'s assignments begin
                </span>
              </Col>
              <Col md="8">
                <Select
                  options={getFirstWeekdaysOfLastSeptember()}
                  value={weekStartDateValue}
                  onChange={option => {
                    handleFieldChange('weekStartDate', option.value);
                  }}
                  className="mb-2"
                />
                <small className="text-muted">
                  Start: {weekStartDateValue?.label} 00:00 &rarr; End:{' '}
                  {weekEndDateValue} 23:59
                </small>
              </Col>
            </Row>
          </Form.Group>

          <Button color="primary" loading={updatePending} onClick={handleSave}>
            Update
          </Button>
        </Container>
      </Page.Body>
    </>
  );
};

export default AssignmentSettings;
