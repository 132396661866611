const objectToSearchParams = src => {
  const searchParams = new URLSearchParams();
  Object.keys(src).forEach(d => {
    if (src[d] !== null) {
      if (Array.isArray(src[d])) {
        src[d].forEach(e => searchParams.append(d, e));
      } else {
        searchParams.append(d, src[d]);
      }
    }
  });

  return searchParams.toString();
};

const appendQueryParam = (uri, paramsObj) => {
  // Create a URL object from the existing URL
  const [path, queryString] = uri.split('?');

  // Use URLSearchParams to manipulate the query string
  const params = new URLSearchParams(queryString);

  // Iterate over the paramsObj and append each key-value pair to the URLSearchParams
  for (const [key, value] of Object.entries(paramsObj)) {
    params.set(key, value);
  }

  // Return the modified URL as a string
  return `${path}?${params.toString()}`;
};

export { objectToSearchParams, appendQueryParam };
