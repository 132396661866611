import styled from 'styled-components';
import { sizes } from '@/styles';
import MessageBox from './components/MessageBox';
import InputBar from './components/InputBar';
import useManageFlowSession from './useManageFlowSession';
import { Loading } from '@/components/common';

const FlowPage = ({
  header = null,
  disableScrollToBottom = false,
  botAvatar = null,
  ...props
}) => {
  const {
    loading,
    state,
    setState,
    sendMessage,
    placeholder,
    isReadOnly,
    currentFlowItemGroup,
    currentMessage,
    isDispatching
  } = useManageFlowSession(props);

  if (loading)
    return (
      <LoadingWrapper>
        <Loading {...props} />
        <div>
          <h3>Loading, please wait...</h3>
        </div>
      </LoadingWrapper>
    );

  return (
    <FlowWrapper {...props}>
      {header}
      <MessageBox
        messages={state.messages}
        sendMessage={sendMessage}
        isDispatching={isDispatching}
        isReadOnly={isReadOnly}
        currentMessage={currentMessage}
        currentFlowItemGroup={currentFlowItemGroup}
        disableScrollToBottom={disableScrollToBottom}
        botAvatar={botAvatar}
      />
      <InputBar
        disabled={isReadOnly}
        placeholder={placeholder}
        value={state.input}
        onChange={e => setState({ input: e.target.value })}
        currentMessage={currentMessage}
        isDispatching={isDispatching}
        currentFlowItemGroup={currentFlowItemGroup}
        onClick={() =>
          sendMessage({
            input: state.input,
            flowItemGroup: currentFlowItemGroup
          })
        }
      />
    </FlowWrapper>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    margin: 1rem;
  }
`;

const FlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 400px;
  justify-content: flex-end;
  align-items: stretch;
  padding: 0 1rem 1rem 1rem;
  flex-grow: 1 !important;
  height: calc(100vh);
  @media (max-width: ${sizes.xl}px) {
    height: calc(100vh - 160px);
  }
  @media (max-width: ${sizes.md}px) {
    height: calc(100vh - 140px);
  }
`;

export default FlowPage;
