import { toaster } from '@/components/common';
import { useCallback, useState } from 'react';

const useClipboard = ({
  clipboardText = null,
  successMessage = 'Copied to clipboard!'
}) => {
  const [clipboardPermission, setClipboardPermission] = useState('default');

  const handleClipboardClick = useCallback(
    async (text = null) => {
      try {
        const textToCopy = clipboardText ?? text;

        if (typeof textToCopy !== 'string') {
          console.error('textToCopy is not string', textToCopy);
          return;
        }

        if (navigator.clipboard) {
          const status = await navigator.permissions.query({
            name: 'clipboard-write'
          });

          setClipboardPermission(status.state);

          if (status.state === 'granted') {
            navigator.clipboard
              .writeText(textToCopy)
              .then(() => toaster.success(successMessage));
          } else {
            throw new Error(
              'Clipboard permission denied. Check browser settings'
            );
          }

          return;
        }

        // fallback to legacy document.execCommand method
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setClipboardPermission('legacy');

        toaster.success(successMessage);
      } catch (err) {
        console.error(err);
        toaster.warning(err);
      }
    },
    [clipboardText, successMessage]
  );

  return {
    handleClipboardClick,
    clipboardPermission
  };
};

export default useClipboard;
