import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button as ReactstrapButton } from 'reactstrap';
import Loading from './Loading';

const Button = ({
  loading,
  disabled = false,
  size,
  color = 'secondary',
  rounded = false,
  icon = false,
  ...props
}) => {
  return (
    <StyledButton
      disabled={!!loading || disabled}
      size={size === 'large' ? 'lg' : size === 'small' ? 'sm' : null}
      tag={props.to ? Link : undefined}
      color={color}
      rounded={rounded}
      icon={icon}
      {...props}>
      {!!loading && !disabled && (
        <LoadingContainer>
          <Loading size="small" color="currentColor" noContainer />
        </LoadingContainer>
      )}
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled(ReactstrapButton)`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.disabled,
  &:disabled {
    border-color: var(--black);
    background-color: var(--gray-lighter);
    color: var(--black);
    opacity: 0.2;
  }

  &.btn-link {
    font-weight: 500;
  }

  > svg {
    width: ${({ size }) => (size === 'sm' ? '1rem' : '1.375rem')};
    height: ${({ size }) => (size === 'sm' ? '1rem' : '1.375rem')};
    ${({ icon }) => !icon && `margin-right: 0.375rem;`}
  }

  ${({ color }) =>
    color === 'secondary' &&
    `
    color: var(--primary);
    &:hover {
      color: var(--primary);
    }
  `}

  ${({ color }) =>
    color === 'plus' &&
    `
    background: linear-gradient(86.77deg, #4353ff -45.62%, #c343ff 116.68%);
    border: unset;
    color: var(--white);

  `}

  ${({ outline }) =>
    outline &&
    `
    border-color: var(--gray-light);
    background-color: var(--white);
    &:hover {
      background-color: var(--gray-lightest);
      border-color: var(--gray-light);
      color: inherit;
    }
    &:focus, &:active {
      background-color: var(--gray-lightest) !important;
      border-color: var(--gray-light) !important;
    }
    &.disabled,
    &:disabled {
      border-color: var(--black);
      background-color: var(--gray-lightest);
      color: var(--black);
      opacity: 0.2;
    }
    `}

${({ transparent }) =>
    transparent &&
    `
    border-color: var(--gray-light);
    color: var(--black);
    background-color: transparent;
    &:hover {
      background-color: transparent;
      border-color: var(--black);
      color: var(--black);
    }
    &:focus, &:active {
      background-color: transparent !important;
      border-color: var(--black) !important;
      color: var(--black) !important;
    }
    &.disabled,
    &:disabled {
      border-color: var(--gray-light);
      background-color: transparent;
      color: var(--gray-light);
      opacity: 0.2;
    }
    `}

  ${({ rounded }) =>
    rounded &&
    `
    border-radius: 99rem;
    `}

  ${({ icon }) =>
    icon &&
    `
    padding: 0.5rem;
    `}

  &.btn-sm {
    ${({ icon }) =>
      icon &&
      `
    padding: 0.375rem;
    `}
  }
`;

const LoadingContainer = styled.span`
  margin-right: 0.5rem;
`;

Button.defaultProps = {
  loading: false
};

export default Button;
