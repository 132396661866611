import styled from 'styled-components';

import { colors } from '@/styles';
import FontSizes from './FontSizes';

const TextAtom = styled.span`
  display: block;
  color: ${({ color }) => colors[color]};
  font-size: ${({ size }) => FontSizes[size].desktop.fontSize}rem;
`;

TextAtom.defaultProps = {
  color: 'bodyText',
  size: 7
};

TextAtom.displayName = 'TextAtom';

//const TextAtom = props => <TextAtomSpan {...props} />

export default TextAtom;
