import { ENVIRONMENT } from '@/config/settings';
import { useCallback } from 'react';
import { useReducer } from 'react';
import { cache } from '@/store';

const useStateReducer = ({
  stateName = 'default',
  ttlMinutes = 0,
  isUseSessionStorage = false,
  initialState = {}
}) => {
  const key = `useStateReducer:${stateName}`;
  initialState =
    ttlMinutes > 0 ? cache.get({ key }) || initialState : initialState;

  const getReducer = useCallback(
    (state, action) => {
      if (ENVIRONMENT !== 'prod') {
        console.log(`State Reducer: ${stateName}`, action, state);
      }
      switch (action.type) {
        case `${stateName}_UPDATE`:
          const updatedState = { ...state, ...action.payload };
          if (ENVIRONMENT !== 'prod') {
            console.log(
              `State Reducer: ${stateName} updated state:`,
              updatedState
            );
          }
          cache.set({
            key,
            value: updatedState,
            ttlMinutes,
            isUseSessionStorage
          });
          return updatedState;
        case `${stateName}_SET`:
          cache.set({
            key,
            value: action.payload,
            ttlMinutes,
            isUseSessionStorage
          });
          return action.payload;
        case `${stateName}_RESET`:
          return initialState;
        default:
          return state;
      }
    },
    [initialState, isUseSessionStorage, key, stateName, ttlMinutes]
  );

  const [state, dispatch] = useReducer(getReducer, initialState);

  return {
    state,
    setState: updatedState =>
      dispatch({ type: `${stateName}_UPDATE`, payload: updatedState }),
    updateState: updatedState =>
      dispatch({ type: `${stateName}_UPDATE`, payload: updatedState }),
    overrideState: updatedState =>
      dispatch({ type: `${stateName}_SET`, payload: updatedState }),
    resetState: () => dispatch({ type: `${stateName}_RESET` })
  };
};

export default useStateReducer;
