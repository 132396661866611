import { setAuthToken } from '@/config/settings';
import { jwtDecode } from 'jwt-decode';

const initialState = {
  isAuthenticated: false,
  userId: null,
  refreshToken: '',
  decodedToken: null,
  userState: {}
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      setAuthToken(action.refreshToken);
      const decoded = jwtDecode(action.refreshToken);

      return {
        ...state,
        isAuthenticated: true,
        refreshToken: action.refreshToken,
        decodedRefreshToken: decoded
      };

    case 'SET_USER':
      let userState = action?.user?.userState ?? initialState.userState;
      if (Array.isArray(action?.user?.userState)) {
        if (!action.user.userState.length) {
          userState = initialState.userState;
        } else {
          action.user.userState.forEach(state => {
            userState[state.key] = state.value;
          });
        }
      }
      return {
        ...state,
        ...action.user,
        userState,
        isAuthenticated: true
      };

    case 'SET_USERSTATE':
      return {
        ...state,
        userState: { ...state.userState, ...action.userState },
        isAuthenticated: true
      };

    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.subscription,
        isAuthenticated: true
      };

    case 'LOGOUT': {
      return initialState;
    }

    default:
      return state;
  }
};

export default user;
