/* @flow */
import styled from 'styled-components';

import { colors } from '@/styles';
import TextAtom from './TextAtom';

const TextS = ({ fontFamily, color, children, ...props }) => (
  <TextAtom fontFamily={fontFamily} color={color} size={7} {...props}>
    {children}
  </TextAtom>
);

TextS.defaultProps = {
  color: 'bodyText',
  fontFamily: 'book'
};

TextS.Link = ({ textColor, underlineColor, children, ...rest }) => (
  <StyledLink textColor={textColor} underlineColor={underlineColor} {...rest}>
    {children}
  </StyledLink>
);

const StyledLink = styled.a`
  cursor: pointer;
  color: ${({ textColor }) => `${colors[textColor]}`};
  border-bottom: ${({ underlineColor }) =>
    underlineColor
      ? `0.125rem solid ${colors[underlineColor]}`
      : `0.125rem solid var(--primary)`};
`;

export default TextS;
