import tracking from '@/analytics/tracking';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { cache, store } from '@/store';

const useTrackPageChange = (
  isPageExitTrackingEnabled = false,
  isOfflineTrackingEnabled = false
) => {
  const { userId } = store.getState()?.user;
  const ref = useRef({
    pathname: null,
    dateEntered: null
  });
  const { pathname } = useLocation();
  const formatPathName = pathname => {
    pathname = pathname
      ?.replace(/\d+/g, '')
      .replace(/\/\//g, '/')
      .replace(/^\/+|\/+$/g, '');

    return pathname?.length ? pathname : 'dashboard';
  };

  const track = useCallback(
    async (action, payload) => {
      const key = 'offlineTracking';
      const value = cache.get({ key }) || [];

      if (!userId && isOfflineTrackingEnabled) {
        value.push({ action, payload, index: value.length });
        cache.set({
          key,
          value,
          ttlMinutes: 60 * 24 * 7,
          isUseSessionStorage: true
        });
        return;
      }

      if (value.length) {
        for (const { action, payload } of value
          .sort((a, b) => b.index - a.index)
          .slice(0, Math.min(value.length, 10))
          .sort((a, b) => a.index - b.index)) {
          await tracking.trackOverride(action, payload, userId);
        }
        cache.remove({ key });
      }

      await tracking.track(action, payload);
    },
    [isOfflineTrackingEnabled, userId]
  );

  useEffect(() => {
    const currentPage = formatPathName(pathname || window.location.pathname);

    if (!currentPage) return;

    const previousPage = ref.current.pathname;

    if (previousPage === currentPage) return;

    const now = new Date();

    if (
      isPageExitTrackingEnabled &&
      previousPage !== currentPage &&
      !!previousPage
    ) {
      try {
        track('Teacher Page Exit', {
          page: previousPage,
          date_entered: ref.current.dateEntered.toISOString(),
          date_exited: now.toISOString(),
          seconds_on_page: !!ref.current.dateEntered
            ? Math.round((now - ref.current.dateEntered) / 1000)
            : null,
          destination: currentPage
        });
      } catch (e) {
        console.error(e);
      }
    }

    try {
      track('Teacher Page Change', {
        page: currentPage,
        previous_page: ref.current.pathname,
        date_entered: now.toISOString()
      });
    } catch (e) {
      console.error(e);
    }

    ref.current = {
      ...ref.current,
      pathname: currentPage,
      dateEntered: now
    };
  }, [isPageExitTrackingEnabled, pathname, track]);
};

export default useTrackPageChange;
