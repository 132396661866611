import { Api } from '@/api';

export const setUser = user => ({
  type: 'SET_USER',
  user
});

export const setUserState = userState => ({
  type: 'SET_USERSTATE',
  userState
});

export const setSubscription = subscription => ({
  type: 'SET_SUBSCRIPTION',
  subscription
});

export const updateUserStateProperty =
  (property, value) => async (dispatch, getState) => {
    if (typeof property !== 'string') return;

    let { userState, userId } = getState().user;

    if (!userId) return;

    if (!userState) userState = {};

    if (userState[property] === `${value}`) return;

    const partialUserState = {};
    partialUserState[property] = `${value}`;

    dispatch(setUserState(partialUserState));

    try {
      const userState = await Api.updateUserState(userId, partialUserState);
      if (userState?.stateValues) {
        dispatch(setUserState(userState?.stateValues));
      }
    } catch (error) {
      dispatch(setUserState(userState));
      throw error;
    }
  };
