import { store } from '@/store';

// find feature toggle for feature flag school, enabled by default
const hasFeature = (featureName, overrideDefaultState = null) => {
  const state = store.getState();
  const schoolFeatures = state.user.featureFlagSchools;
  const schoolId = state.school.schoolId;

  if (schoolFeatures && schoolId && featureName) {
    // Find the features for this school
    const feature = schoolFeatures.find(ffs => ffs.schoolId === schoolId);

    // Get the toggle for this feature
    if (feature) {
      return feature[featureName] === 'Enabled';
    }
  }

  return overrideDefaultState ?? true;
};

const featureToggle = {
  NotSet: 'NotSet',
  Disabled: 'Disabled',
  Enabled: 'Enabled'
};

const getDynamicFeatures = () => {
  try {
    const state = store.getState();
    const schoolFeatures = state.user.featureFlagSchools;

    if (!schoolFeatures?.length) return [];

    const dynamicFeatures = [].concat.apply(
      [],
      schoolFeatures?.map(s =>
        s.dynamicFeatures.map(d => ({ ...d, schoolId: s.schoolId }))
      )
    );

    return dynamicFeatures;
  } catch (e) {
    console.error(e);
  }
  return [];
};

const hasDynamicFeature = featureName => {
  if (!featureName) return featureToggle.NotSet;

  try {
    const dynamicFeatures = getDynamicFeatures();

    const dynamicFeature = dynamicFeatures.find(
      df => df.description === featureName
    );

    if (
      !!dynamicFeature &&
      [featureToggle.Disabled, featureToggle.Enabled].includes(
        dynamicFeature?.featureToggle
      )
    ) {
      return dynamicFeature.featureToggle;
    }
  } catch (e) {
    console.error(e);
  }

  return featureToggle.NotSet;
};

export { hasFeature, hasDynamicFeature, featureToggle, getDynamicFeatures };
