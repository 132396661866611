import { ENVIRONMENT } from '@/config/settings';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const IntercomAppProvider = ({ children = null }) => {
  const { user, school } = useSelector(({ user, school }) => ({
    user,
    school
  }));
  const bootedUserId = useRef(null);

  useEffect(() => {
    console.log('intercom boot', {
      ENVIRONMENT,
      eediSchoolUserHash: user?.intercom?.eediSchoolUserHash,
      eediSchoolTestUserHash: user?.intercom?.eediSchoolTestUserHash,
      userId: user.userId,
      school,
      isAuthenticated: user?.isAuthenticated,
      bootedUserId: bootedUserId.current,
      userRole: user?.userRole
    });

    if (
      !user?.isAuthenticated ||
      bootedUserId.current === user?.userId ||
      !school?.schoolId
    ) {
      return;
    }

    if (bootedUserId.current) {
      bootedUserId.current = null;
      window.Intercom('shutdown');
    }

    // we have different intercom apps for teachers and parents
    let app_id;

    if (user?.userRole === 'Teacher') {
      app_id = ENVIRONMENT === 'prod' ? 'fk0iatma' : 'ac1q60zp';
    }

    if (!app_id) return;

    console.log('booting intercom', { app_id });

    window.Intercom('boot', {
      app_id,
      user_id: user.userId,
      user_hash:
        ENVIRONMENT === 'prod'
          ? user?.intercom?.eediSchoolUserHash
          : user?.intercom?.eediSchoolTestUserHash
    });

    bootedUserId.current = user?.userId;
  }, [user, school]);

  return children;
};

export default IntercomAppProvider;
