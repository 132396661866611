import React, { useState, useContext } from 'react';
import { CookieContext, cookies } from './CookieProvider';
import styled, { keyframes } from 'styled-components';
import { Button, Card, Modal, Switch } from '@/components/common';
import { hashObjectValues } from '@/helpers/hashCodeHelper';

const growAnimation = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const CookieCard = styled(Card)`
  box-shadow: var(--shadow3);
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 1000;
  max-width: 25rem;
  animation: ${growAnimation} 0.125s ease-out;
  transform-origin: bottom left;
`;

const CookieBanner = () => {
  const { updateConsent } = useContext(CookieContext);
  const [cookieToggled, setCookieToggled] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = name => {
    setCookieToggled({
      ...cookieToggled,
      [name]: !cookieToggled[name]
    });
  };

  const handleUpdateConsent = (consent = null) => {
    setIsModalOpen(false);
    updateConsent(
      cookies.map(c => ({
        name: c.name,
        hash: hashObjectValues(c),
        consent: c.disabled
          ? c.defaultConsent
          : consent ?? cookieToggled[c.name] ?? c.defaultConsent
      }))
    );
  };

  return (
    <>
      <CookieCard>
        <Card.Body>
          <h3>Cookies! 🍪</h3>
          <p className="mb-1">
            We use cookies to make Eedi work. We'd like to use other cookies to
            improve your experience on our site&mdash;but only if that's okay
            with you!
          </p>
          <p>
            Read our full{' '}
            <a
              href="https://eedi.com/legal/cookies"
              target="_blank"
              rel="noopener noreferrer">
              cookie policy
            </a>
            .
          </p>
          <Button onClick={() => handleUpdateConsent(true)} color="primary">
            Accept
          </Button>
          <Button
            onClick={() => handleUpdateConsent(false)}
            color="secondary"
            outline
            className="ml-2">
            Reject
          </Button>
          <Button
            onClick={() => setIsModalOpen(true)}
            color="secondary"
            outline
            className="ml-2">
            Manage cookies
          </Button>
        </Card.Body>
      </CookieCard>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <Modal.Header toggle={() => setIsModalOpen(false)}>
          Manage Cookie Preferences
        </Modal.Header>
        <Modal.Body>
          {cookies.map(cookie => (
            <div key={cookie.name} className="mb-3">
              <Switch
                enabled={cookieToggled?.[cookie.name] || cookie.defaultConsent}
                onClick={() => handleToggle(cookie.name)}
                label={cookie.label}
                disabled={cookie.disabled}
              />
              <p className="text-muted" style={{ marginRight: '5rem' }}>
                {cookie.description}
              </p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleUpdateConsent()} color="primary">
            Save & Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CookieBanner;
