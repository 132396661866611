import { Label, Input } from '@/components/common';
import styled from 'styled-components';

const RadioButton = ({
  label,
  onClick,
  checked,
  value,
  color,
  name,
  disabled,
  onChange = () => {},
  ...props
}) => (
  <RadioButtonWrapper onClick={() => onClick(value)} color={color} {...props}>
    <Input
      type="radio"
      name={name}
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    <Label>{label}</Label>
  </RadioButtonWrapper>
);

const RadioButtonWrapper = styled.div`
  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    //position: absolute;
    //left: -9999px;
    display: none;
  }
  input[type='radio']:checked + label,
  input[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    display: inline-block;
  }
  input[type='radio']:checked + label:before,
  input[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--black);
    border-radius: 100%;
    background: var(--white);
  }
  input[type='radio']:checked + label:before {
    border: 1px solid ${props => props.color || 'var(--black)'};
  }
  input[type='radio']:checked + label:after,
  input[type='radio']:not(:checked) + label:after {
    content: '';
    width: 1rem;
    height: 1rem;
    background: ${props => props.color || 'var(--black)'};
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }
  input[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  input[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export default RadioButton;
