import { EmojiSpan, Tooltip } from '@/components/common';
import styled from 'styled-components';

const GlobalRankPill = ({ id, rank, tooltip = null, ...props }) => {
  if (!rank) return null;

  return (
    <div>
      <PointsContainer id={id ?? `rank-${rank}`} {...props}>
        <EmojiSpan
          emoji="🌐"
          ariaLabel="globe"
          style={{ fontSize: '1.4rem' }}
        />
        <div>{rank}</div>
      </PointsContainer>
      <Tooltip target={id ?? `rank-${rank}`}>
        {tooltip ?? `Ranked #${rank} in the global student leaderboard`}
      </Tooltip>
    </div>
  );
};

const PointsContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: var(--gray-lightest);
  border-radius: 3rem;
  padding: 0.5rem;
  z-index: 1;
  align-items: center;
  height: 2.5rem;
  min-width: 4.5rem;
  z-index: 100;
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  font-weight: 500;
  margin: 0 0.5rem 0;
  img {
    top: -2px;
    position: relative;
    height: 100%;
    min-height: 2rem;
    user-select: none;
  }
`;

export default GlobalRankPill;
