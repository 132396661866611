import { Loading } from '@/components/common';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';

const getGroupLabel = groupValue =>
  typeof groupValue === 'string' ? groupValue : groupValue.label;

const GroupItem = ({ item, ...props }) => {
  const { itemComponent: ItemComponent, renderItem, prepareProps } = props;

  if (ItemComponent) {
    const props = prepareProps ? prepareProps(item) : { ...item };
    return <ItemComponent {...props} />;
  }

  if (renderItem) {
    return renderItem(item);
  }

  return null;
};

const Group = ({ data, ...props }) => {
  const groupLabel = getGroupLabel(data.group);
  const ItemsHeader = props.itemsHeader || DefaultItemsHeader;

  return (
    <React.Fragment key={props.key}>
      <ItemsHeader>{groupLabel}</ItemsHeader>
      {data.items.map((item, i) => (
        <GroupItem key={`${groupLabel}-${i}`} item={item} {...props} />
      ))}
    </React.Fragment>
  );
};

const GroupedItemList = ({
  items,
  getGroupByValue,
  sortGroups = (a, b) => (a <= b ? 1 : -1),
  hasMore,
  loader,
  ...props
}) => {
  const groupedItems = useMemo(() => {
    if (!getGroupByValue || !items) {
      return undefined;
    }

    const grouped = items.reduce((acc, val) => {
      const groupValue = getGroupByValue(val);
      const groupLabel =
        typeof groupValue === 'string' ? groupValue : groupValue.label;
      const group = acc[groupLabel] ? acc[groupLabel].items : [];
      return {
        ...acc,
        [groupLabel]: {
          items: [...group, val],
          group: groupValue
        }
      };
    }, {});

    return Object.values(grouped).sort((a, b) => sortGroups(a.group, b.group));
  }, [getGroupByValue, items, sortGroups]);

  if (!items) {
    return null;
  }

  const useInfiniteScroll =
    props.loadMore && typeof props.loadMore === 'function';

  const content = !!groupedItems
    ? Object.values(groupedItems).map((g, i) => (
        <React.Fragment key={i}>
          <Group key={i} data={g} {...props} />
        </React.Fragment>
      ))
    : useInfiniteScroll
    ? items.map(item => (
        <React.Fragment key={item.groupId}>
          <GroupItem key={item.groupId} item={item} {...props} />
        </React.Fragment>
      ))
    : items.map(
        (item, i) =>
          i < 20 && (
            <React.Fragment key={item.groupId}>
              <GroupItem key={item.groupId} item={item} {...props} />
            </React.Fragment>
          )
      );

  if (useInfiniteScroll) {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={props.loadMore}
        hasMore={!!hasMore}
        loader={loader || <Loading key={0} margin={4} />}
        useWindow={true}>
        {content}
      </InfiniteScroll>
    );
  }
  return content;
};

const DefaultItemsHeader = styled.h4`
  margin-top: 2rem;
  padding: 0.5rem 0.75rem;
`;

export default GroupedItemList;
