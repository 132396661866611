import styled from 'styled-components';
import { Link } from '@/components/common';

const BoxNavi = styled.div`
  display: flex;
  align-items: center;
`;

BoxNavi.Item = styled(Link)`
  border-radius: 0.25rem;
  border: 1px solid
    var(${props => (props.active ? '--info' : '--gray-lighter')});
  background: var(
    ${props => (props.active ? '--blue-lighter' : 'transparent')}
  );
  width: 3rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  margin-left: 0.375rem;
  color: var(--black) !important;
  font-weight: normal !important;
`;

export default BoxNavi;
