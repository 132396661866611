import styled from 'styled-components';
import { Navbar as BootstrapNavbar } from 'reactstrap';
import { Container } from '@/components/common';
import { sizes, media } from '@/styles';

const Navbar = props => (
  <StyledNavbar light expand="md" {...props}>
    <StyledContainer>{props.children}</StyledContainer>
  </StyledNavbar>
);

const StyledNavbar = styled(BootstrapNavbar)`
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(var(--black-rgba), 0.1);
  padding: 0.5rem 0.75rem;
  min-height: 5rem;
  ${media.md`
    padding: 0 0.75rem;
  `};
  flex-shrink: 0;
`;

const StyledContainer = styled(Container)`
  @media (max-width: ${sizes.lg}px) {
    max-width: 100%;
  }
`;

export default Navbar;
