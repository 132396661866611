import { Icon } from '@/components/common';
import { clamp } from '@/helpers/mathClamp';
import { useMemo } from 'react';
import styled from 'styled-components';

const PageIndicator = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  &.active {
    cursor: default;
    color: var(--primary);
  }

  &:not(.active):hover {
    background-color: var(--secondary);
  }
`;

const EllipsesIndicator = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const HoverContainer = styled.div`
  cursor: pointer;
  color: var(--black);

  &:not(.disabled):hover {
    color: var(--gray);
  }

  &.disabled {
    color: var(--gray-light);
    cursor: default;
  }
`;

export const PaginationControls = ({
  pageIndex,
  setPageIndex,
  pageCount,
  canNextPage,
  canPreviousPage,
  style
}) => {
  const pageOptions = useMemo(() => {
    if (!pageCount) {
      return [0];
    }

    const halfWindowWidth = 1;
    const lastIndex = pageCount - 1;

    let leftLimit = pageIndex - halfWindowWidth;
    let rightLimit = pageIndex + halfWindowWidth;

    if (leftLimit < 0) {
      rightLimit += Math.abs(leftLimit);
      leftLimit = 0;
    }
    if (rightLimit > lastIndex) {
      leftLimit -= rightLimit - lastIndex;
      rightLimit = lastIndex;
    }
    leftLimit = clamp(leftLimit, 0, lastIndex);
    rightLimit = clamp(rightLimit, 0, lastIndex);

    const pageCrumbs = [];
    if (leftLimit > 0) {
      pageCrumbs.push(0);
    }
    if (leftLimit > 1) {
      pageCrumbs.push(null);
    }
    for (let i = leftLimit; i <= rightLimit; ++i) {
      pageCrumbs.push(i);
    }
    if (lastIndex - rightLimit > 1) {
      pageCrumbs.push(null);
    }
    if (rightLimit < lastIndex) {
      pageCrumbs.push(lastIndex);
    }

    return pageCrumbs;
  }, [pageCount, pageIndex]);

  const nextPage = () => {
    if (!canNextPage) {
      return;
    }
    setPageIndex(pageIndex + 1);
  };
  const previousPage = () => {
    if (!canPreviousPage) {
      return;
    }
    setPageIndex(pageIndex - 1);
  };

  // Don't show pagination controls if we only have one page
  if (pageCount <= 1) {
    return null;
  }

  return (
    <div className="d-flex align-items-center" style={style}>
      <HoverContainer
        className={!canPreviousPage ? 'disabled' : ''}
        onClick={previousPage}>
        <Icon color="currentColor" name="back" />
      </HoverContainer>

      {pageOptions.map((v, i) => {
        if (v === null) {
          return <EllipsesIndicator key={i}>&hellip;</EllipsesIndicator>;
        }
        return (
          <PageIndicator
            key={`val-${v}`}
            className={pageIndex === v ? 'active' : ''}
            onClick={() => setPageIndex(v)}>
            {v + 1}
          </PageIndicator>
        );
      })}

      <HoverContainer
        className={!canNextPage ? 'disabled' : ''}
        onClick={nextPage}>
        <Icon color="currentColor" name="forward" />
      </HoverContainer>
    </div>
  );
};

export default PaginationControls;
