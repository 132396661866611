import { colors } from '@/styles';

function Logo({
  includeName = true,
  loading = false,
  color = colors.black,
  size = '1.5rem'
}) {
  if (includeName) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 94 22"
        style={{ height: size }}>
        <path
          fill={loading ? 'var(--gray-lighter)' : color}
          fillRule="evenodd"
          d="M-.001 21.548h4.4V0h-4.4v21.548zm8.8-12.929h13.198v-4.31H8.798v4.31zm13.198 8.62H8.798v-4.31h13.199v4.31zM91.422 0c-1.423 0-2.577 1.13-2.577 2.524 0 1.394 1.154 2.524 2.577 2.524s2.577-1.13 2.577-2.524C94 1.13 92.845 0 91.422 0zm-2.137 21.564h4.274V6.992h-4.274v14.572zM77.279 9.765c-2.054 0-3.488 1.856-3.488 4.513 0 2.7 1.338 4.513 3.33 4.513 1.816 0 3.647-1.25 3.647-4.046v-.902c0-1.28-.377-2.344-1.091-3.078a3.313 3.313 0 00-2.398-1zm-1.194-3.208c1.909 0 3.56.838 4.418 2.243l.234.383V.02h4.274v21.545h-3.786l-.331-2.054-.235-.032c-.553.942-1.91 2.522-4.794 2.522-3.793 0-6.443-3.175-6.443-7.722 0-4.546 2.74-7.721 6.663-7.721zm-13.102 6.288l-.004-.127c-.06-1.994-1.217-3.233-3.017-3.233-1.839 0-2.973 1.177-3.111 3.229l-.01.131h6.142zm2.477-3.99c1.173 1.378 1.794 3.275 1.794 5.485v.967h-10.44l.005.128c.036.984.293 1.816.744 2.408.558.733 1.397 1.104 2.493 1.104 1.645 0 2.492-.922 2.921-1.74l3.658 1.46C65.397 20.818 63.09 22 60.12 22c-2.271 0-4.185-.711-5.536-2.056-1.374-1.37-2.1-3.34-2.1-5.697 0-2.311.731-4.262 2.116-5.642 1.345-1.34 3.2-2.048 5.363-2.048 2.317 0 4.218.794 5.498 2.298zm-25.74 3.739h9.618V8.98H39.72V3.632h10.34V.02H35.32v21.545h14.9v-3.613h-10.5v-5.357z"
          clipRule="evenodd"></path>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      style={{ width: size, height: size }}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={loading ? 'var(--gray-lighter)' : color}
          d="M0 100h20V0H0z"></path>
        <path
          fill={loading ? 'var(--gray-lighter)' : color}
          d="M40 40h60V20H40z"></path>
        <path
          fill={loading ? 'var(--gray-lighter)' : color}
          d="M40 80h60V60H40z"></path>
      </g>
    </svg>
  );
}

export default Logo;
