import { ENVIRONMENT } from '@/config/settings';
export class Intercom {
  constructor() {
    this._userId = null;
    this._userRole = null;
  }
  identify(user) {
    if (!!user?.userRole) {
      this._userRole = user.userRole;
    }

    if (
      !window.Intercom ||
      this._userId === user?.userId ||
      user?.userRole !== 'Teacher'
    )
      return;

    // Narrow the scope of data
    const userObject = {
      user_id: user.userId,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      date_joined: user.dateJoined,
      date_joined_at: user?.dateJoined
        ? Math.floor(new Date(user.dateJoined).getTime() / 1000)
        : null,
      username: user.userName,
      user_type: user.userRole,
      date_of_birth: user.dateOfBirth,
      school_name: user.schoolName,
      school_id: user.schoolId,
      verified_in_school: user.verifiedInSchool,
      is_school_administrator: user.isSchoolAdministrator,
      host: window.location.host,
      source: 'teacher_' + ENVIRONMENT
    };

    window.Intercom('update', userObject);

    this._userId = user.userId;
    this._userRole = user.userRole;
  }

  track(event, data) {
    if (!window.Intercom) return null;
    window.Intercom('trackEvent', event, data);
  }

  set(props) {
    if (!window.Intercom) return null;
    window.Intercom('update', props);
  }

  shutdown() {
    window.Intercom('shutdown');
  }
}
