import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Icon, toaster } from '@/components/common';
import { colors } from '@/styles';
import { FilledCircle, SchoolIcon } from '@/assets/icons';
import { Api } from '@/api';
import { clearFields } from '@/actions/joinClassStepper';
import useLoadUserContext from '@/hooks/useLoadUserContext';
import tracking from '@/analytics/tracking';

const ClassJoin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const schoolId = useSelector(s => s.school.schoolId);
  const teacherId = useSelector(s => s.school.teacherId);
  const groups = useSelector(s => s.school.groups);
  const fields = useSelector(s => s.joinClassStepper.groupFields);
  const { loadUserContext } = useLoadUserContext();
  const dispatch = useDispatch();
  const groupId = fields.groupId;

  const handleJoinClass = useCallback(async () => {
    setLoading(true);

    try {
      const groupIds = [...groups.map(group => group.groupId), groupId];
      await Api.updateTeacherGroups(teacherId, groupIds);
      dispatch(clearFields());
      await loadUserContext(schoolId);
      tracking.track('Class Joined');
      toaster.success("You've joined this class");
      navigate(`/${schoolId}`);
    } catch (e) {
      toaster.danger('Failed to join class');
    }

    setLoading(false);
  }, [
    groupId,
    groups,
    teacherId,
    navigate,
    schoolId,
    dispatch,
    loadUserContext
  ]);

  return (
    <>
      <div className="text-center mb-4">
        <h1>You're about to join</h1>
      </div>

      <Card>
        <Card.Body>
          <div className="d-flex align-items-center mb-4">
            <FilledCircle
              className="mr-3"
              size={5}
              color={colors.purpleLighter}>
              <SchoolIcon name={fields.code} size="3.5rem" />
            </FilledCircle>
            <div className="flex-fill">
              <h2>{fields.code}</h2>
              <h4 className="subtitle">{fields.departmentName}</h4>
            </div>
          </div>
          <Button
            onClick={handleJoinClass}
            color="primary"
            loading={loading}
            block>
            Confirm
          </Button>
        </Card.Body>
      </Card>

      <Button
        color="link"
        onClick={() =>
          navigate(`/${schoolId}/classes/new`, { state: { text: '' } })
        }
        className="mt-3">
        <Icon name="arrowLeft" color="primary" />
        Search again
      </Button>
    </>
  );
};

export default ClassJoin;
