import styled from 'styled-components';
import prepareInitials from '@/helpers/prepareInitials';
import stringToColor from '@/helpers/stringToColor';
import { colors } from '@/styles';
import { useSelector } from 'react-redux';
import { getImageUrl } from '@/config/settings';
import { getHashCode } from '@/helpers/hashCodeHelper';

const Avatar = ({
  userId = null,
  width = '2.5',
  rounded = true,
  name,
  botAvatar = null,
  ...props
}) => {
  let size = 'thumbnail';
  if (width > 2.5) {
    size = 'avatar';
  }

  const lastUpdatedAvatar = useSelector(s => s.user.lastUpdatedAvatar);
  const number = userId ? (getHashCode(userId) % 50) + 1 : null;
  const defaultUrl = getImageUrl(`avatars/default/${size}${number}.png`);
  const imageUrl =
    userId <= 0
      ? 'https://images.diagnosticquestions.com/avatars/eedi/thumbnail.png'
      : getImageUrl(`avatars/${userId}/${size}.png?${lastUpdatedAvatar}`);

  return (
    <StyledAvatar
      width={width}
      rounded={rounded}
      color={stringToColor(name)}
      {...props}>
      {userId ? (
        <>
          {userId <= 0 && botAvatar ? (
            <>{botAvatar}</>
          ) : (
            <AvatarImage
              src={imageUrl}
              alt={props.name ?? 'avatar'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultUrl;
              }}
            />
          )}
        </>
      ) : (
        <Text>{prepareInitials(name)}</Text>
      )}
    </StyledAvatar>
  );
};

const StyledAvatar = styled.div`
  background: ${({ color }) => colors[color] || 'var(--primary)'};
  width: ${({ width }) => width}rem;
  height: ${({ width }) => width}rem;
  border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
  overflow: hidden;
  flex-shrink: 0;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
`;

const Text = styled.span`
  display: flex;
  flex: 1 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${({ width }) => (width && (width / 2.5).toFixed(1)) || 1}rem;
  color: var(--white);
  padding: 0.25rem;
`;

Avatar.defaultProps = {
  imageUrl: '',
  name: ''
};

export default Avatar;
