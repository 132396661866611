import { isAuthenticated } from '@/config/settings';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const Home = () => {
  const schoolMembershipStatus = useSelector(
    store => store.school.currentSchool?.teacher?.schoolMembershipStatus
  );
  const schoolId = useSelector(s => s.school.schoolId);
  const groups = useSelector(s => s.school.groups);
  const demoGroups = useSelector(s => s.school?.demoGroups);

  // filter demo groups out of groups
  const demoGroupIds = demoGroups ? demoGroups.map(g => g.groupId) : [];
  const hasExistingGroups = groups
    ? !!groups.filter(g => !demoGroupIds.includes(g.groupId)).length
    : false;

  // Figure out what the homepage should be
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  if (!schoolId) {
    return <Navigate to="/schools" replace />;
  }

  if (schoolMembershipStatus !== 'Confirmed') {
    return <Navigate to={`/${schoolId}/pending`} replace />;
  }

  if (!hasExistingGroups) {
    return <Navigate to={`/${schoolId}/classes/new`} />;
  }

  return <Navigate to={`/${schoolId}`} />;
};

export default Home;
