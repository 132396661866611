/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { Logo, Navbar, Nav, Link } from '@/components/common';
import { useSelector } from 'react-redux';

const Navigation = ({
  top,
  bottom,
  leftComponent,
  rightComponent,
  middleComponent,
  ...props
}) => {
  const schoolId = useSelector(store => store.school.schoolId);
  return (
    <Header bottom={bottom} {...props}>
      <HeaderLeft>
        {leftComponent ? (
          leftComponent
        ) : !bottom ? (
          <LogoContainer>
            <Link to={`/${schoolId}`}>
              <Logo includeName={false} size="1.75rem" />
            </Link>
          </LogoContainer>
        ) : null}
      </HeaderLeft>
      <HeaderCenter>{middleComponent ? middleComponent : null}</HeaderCenter>
      <HeaderRight>{rightComponent ? rightComponent : null}</HeaderRight>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 1rem;
  ${({ bottom }) =>
    bottom
      ? `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  `
      : `
  position: relative;
  `}
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const HeaderCenter = styled.div`
  display: flex;
  text-align: center;
  white-space: nowrap;
  justify-content: center;
  text-overflow: ellipsis;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(5.5rem, 7vw, 8rem);
  height: var(--header-height);
  position: absolute;
  left: 0;
  top: 0;
`;

export default Navigation;
