import { useCallback, useContext, useEffect, useRef } from 'react';
import { getSignalRContext } from '@/helpers/getSignalRContext';

export const useSignalROn = (method, callback, hubName = 'LessonHub') => {
  let signalRConnection = useContext(getSignalRContext(hubName));

  const callbackRef = useRef();
  callbackRef.current = callback;

  const staticCallback = useCallback(
    (...args) => callbackRef.current(...args),
    []
  );

  useEffect(() => {
    if (!signalRConnection) {
      return;
    }

    signalRConnection.on(method, staticCallback);
    return () => signalRConnection.off(method, staticCallback);
  }, [method, signalRConnection, staticCallback]);
};
