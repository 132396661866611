import FullPageError from './FullPageError';
import { useState, useEffect } from 'react';
import { ENVIRONMENT } from '@/config/settings';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (ENVIRONMENT === 'production') {
      const handleError = (error, info) => {
        console.error('555 error', error);
        setHasError(true);
        setError(error);
        setInfo(info);
      };

      const errorListener = (event) => {
        handleError(event.error, event.errorInfo);
      };

      window.addEventListener('error', errorListener);

      return () => {
        window.removeEventListener('error', errorListener);
      };
    }
  }, []);

  if (hasError) {
    return (
      <FullPageError
        title="Something went wrong"
        description="Please refresh your browser and try again"
        isLogoutOption={true}
        error={error}
        info={info}
      />
    );
  }

  return children;
};

export default ErrorBoundary;
