import analytics from '@/analytics';
// Import polyfill for fetch on IE11
import 'whatwg-fetch';
import { BUILD_ID, ENVIRONMENT, getAuthToken } from '../config/settings';
import { AuthorizationError } from './errors';
import trim from 'lodash/trim';
import saveFile from './saveFile';

const nuclearNoCacheHeaders = {
  pragma: 'no-cache',
  'cache-control': 'max-age=0, no-cache, no-store',
  Vary: '*'
};

let USER_AGENT = '';

// Enable debug output when in Debug mode
const DEBUG_MODE = true;

/**
 * Debug or not to debug
 */
const debug = (title, output) => {
  if (DEBUG_MODE) {
    console.log(title, output);
  }
};

/**
 * Race two promises against each other ... one to fetch a request to & from the API
 *                                          and one for a timeout of 15 seconds
 * @param method
 * @param url
 * @param params
 * @param body
 * @returns {Promise.<*>}
 */
const fetcher = async (method, url, body, settings = null) => {
  const TIMEOUT_LENGTH = settings?.timeout > 0 ? settings.timeout : 180000; // 180 seconds
  const token = settings?.token ?? getAuthToken();

  // eslint-disable-next-line no-unused-vars
  let timeoutPromise = new Promise((resolve, reject) =>
    setTimeout(
      () => reject(new Error('Your request timed out.  Please try again.')),
      TIMEOUT_LENGTH
    )
  );

  let fetchPromise = new Promise((resolve, reject) => {
    if (!method) {
      throw new TypeError('Invalid Request Method');
    }

    if (!url) {
      throw new TypeError('Invalid Request Endpoint');
    }

    // Increment module request counter
    Client.requestCounter += 1;

    const request = buildFetchRequest(method, url, body, token, settings);
    debug(`API Request #${Client.requestCounter}`, request);

    // Make the request
    return fetch(request)
      .then(checkResponseIsValid)
      .then(handleValidResponse(Client.requestCounter, settings))
      .then(checkResponseForErrors)
      .then(responseBody => resolve(responseBody))
      .catch(e => {
        if (e instanceof AuthorizationError) {
          //store.dispatch(logout());
        }

        if (e.name === 'AbortError') {
          reject(e);
          return;
        }

        e.domain = 'Client.fetcher.fetch';
        e.data = { url, body };
        e.code = e.status_code;
        //analytics.trackError(e);
        reject(e);
      });
  });

  return Promise.race([fetchPromise, timeoutPromise]);
};

/**
 * Replace matching params in API routes and returns a Request object
 * @param url
 * @param params
 * @returns {Request}
 * @see ../constants/api.js
 */
const buildFetchRequest = (method, url, data, token, settings) => {
  const extraHeaders = settings?.headers ? settings.headers : {};

  const headerValues = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'User-Agent': USER_AGENT,
    Version: BUILD_ID,
    Source: `Teacher_${ENVIRONMENT}`,
    'X-Is-Trackable': 'true',
    'X-Is-Beta': 'true',
    ...extraHeaders,
    ...nuclearNoCacheHeaders
  };

  const isFormData = headerValues['Content-Type'] === 'multipart/form-data';

  if (isFormData) {
    delete headerValues.Accept;
    delete headerValues['Content-Type'];
  }

  const headers = new Headers(headerValues);

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  let req = {
    method: method.toUpperCase(),
    headers
  };

  // If this is a get request append data on to the query string
  if (data) {
    if (method === 'GET') {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(d => {
        if (Array.isArray(data[d])) {
          data[d].forEach(e => searchParams.append(d, e));
        } else {
          searchParams.append(d, data[d]);
        }
      });

      url += '?' + searchParams.toString();
    } else if (isFormData) {
      req['body'] = data;
    } else {
      req['body'] = JSON.stringify(data);
    }
  }

  if (settings?.signal) {
    req.signal = settings.signal;
  }

  return new Request(url, req);
};

/**
 * @param response
 * @returns {*}
 */
const checkResponseIsValid = response => {
  if (response?.status === 401) {
    throw new AuthorizationError(
      'Authorisation has been denied for this request.'
    );
  }

  return response;
};

const handleValidResponse =
  (requestCounterId, settings = undefined) =>
  response => {
    try {
      if (response.status === 204) {
        return {};
      }

      // This can occur if the API return an OK result with no content
      // TODO - not sure if an empty object is the best thing to return
      // depending on he assumptions made about the request...
      if (!response?.headers?.get('content-type')) return {};

      switch (settings?.readBodyAs) {
        case 'text':
          return response.text();
        case 'blob':
          return response.blob();
        case 'formData':
          return response.formData();
        case 'arrayBuffer':
          return response.arrayBuffer();
        case 'file':
          return saveResponseAsFile(response);

        case 'json':
          return response.json();

        case 'response':
          return response;

        case 'none':
          return;

        default:
          return response.json();
      }
    } catch (e) {
      //analytics.trackError(e);
      console.error(`Api Request #${requestCounterId}`, e);
      throw new SyntaxError('Improper Response Received');
    }
  };

// If the response has an error or a message then throw an error
const checkResponseForErrors = response => {
  if (!response) return;

  const error = response.message || response.error;
  if (error) {
    if (response.modelState) {
      throw new FormError(error, response.modelState);
    }

    throw new Error(error);
  }

  return response;
};

const saveResponseAsFile = async response => {
  const header = response.headers.get('content-disposition') ?? '';

  const parts = header.split(';');

  const fileName = parts
    .find(part => part.includes('filename='))
    ?.split('=')[1];

  if (!response.ok || (!header && !fileName)) {
    throw new Error('Error downloading file');
  }

  // Filename may include double quotes for some reason
  const cleanedFileName = trim(fileName, '  "');

  saveFile(await response.blob(), cleanedFileName);
};

const Client = {
  requestCounter: 0,
  /**
   *
   * @param {string} key for API url
   * @param params
   * @return {Promise.<*>}
   */
  get: (url, params, settings = null) => fetcher('GET', url, params, settings),
  post: (url, params, settings = null) =>
    fetcher('POST', url, params, settings),
  patch: (url, params, settings = null) =>
    fetcher('PATCH', url, params, settings),
  put: (url, params, settings = null) => fetcher('PUT', url, params, settings),
  delete: (url, params, settings = null) =>
    fetcher('DELETE', url, params, settings)
};

export class FormError extends Error {
  constructor(message, modelState) {
    super(message);

    let formattedModelState = {};

    for (const [key, error] of Object.entries(modelState)) {
      if (error) {
        formattedModelState[key] = { message: error };
      }
    }
    this.modelState = formattedModelState;

    // a workaround to make `instanceof` work in ES5 https://github.com/babel/babel/issues/3083
    this.constructor = FormError;
    this.__proto__ = FormError.prototype;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormError);
    }
  }
}

export default Client;
