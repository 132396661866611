import { changeField, clearFields } from '@/actions/joinClassStepper';
import {
  Button,
  Card,
  Input,
  Select,
  Icon,
  Form,
  toaster,
  Loading
} from '@/components/common';
import FormValidator from '@/components/FormValidator';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tracking from '@/analytics/tracking';
import moment from 'moment';
import analytics from '@/analytics';
import { Api, useApiQuery } from '@/api';
import { compact, uniq } from 'lodash';
import useLoadUserContext from '@/hooks/useLoadUserContext';

const ClassAddForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teacherId = useSelector(s => s.school.teacherId);
  const schoolId = useSelector(s => s.school.schoolId);
  const [loading, setLoading] = useState(false);
  const { loadUserContext } = useLoadUserContext();
  const fields = useSelector(s => s.joinClassStepper.groupFields);
  const { code = '', newlyAddedGroups = [] } = fields;

  const { data, loading: loadingData } = useApiQuery(async () => {
    const yearGroups = await Api.getSchoolYearGroups(schoolId);
    const school = await Api.getSchool(schoolId);
    return { yearGroups: yearGroups || [], school };
  }, [schoolId]);

  const yearGroups = data?.yearGroups;

  const handleClassDetailsChange = (fieldName, value) => {
    if (fieldName === 'code' && fields.code === '' && value) {
      navigate(`/${schoolId}/classes/new`);
    }
    dispatch(changeField(fieldName, value));
  };

  const searchAgain = () =>
    navigate(`/${schoolId}/classes/new`, { state: { text: fields.code } });

  const addNewYearGroup = item => {
    const { yearGroupIds = [], newlyAddedGroups = [] } = fields;
    handleClassDetailsChange('newlyAddedGroups', [
      ...newlyAddedGroups,
      {
        name: item.label,
        yearGroupId: item.value
      }
    ]);
    handleClassDetailsChange('yearGroupIds', [...yearGroupIds, item.value]);
  };

  const handleYearGroupSelect = yearGroupIds => {
    handleClassDetailsChange('yearGroupIds', yearGroupIds);
  };

  const createYearGroups = useCallback(async () => {
    const existingYearGroupIds = yearGroups.map(yg => yg.yearGroupId);

    const yearGroupsToCreate = fields?.newlyAddedGroups
      .filter(group => !existingYearGroupIds.includes(group.yearGroupdId))
      .map(group => ({
        id: null,
        label: group.name,
        isSelected: true
      }));

    // If yearGroupsToCreate has contents, create new groups and return ids;
    if (yearGroupsToCreate.length) {
      return await Promise.all(
        yearGroupsToCreate.map(g => Api.createYearGroup(schoolId, g.label))
      );
    }
    return [];
  }, [fields.newlyAddedGroups, schoolId, yearGroups]);

  const addGroup = useCallback(async () => {
    setLoading(true);

    try {
      const newlyCreatedYearGroups = await createYearGroups();
      const newYearGroupIds = newlyCreatedYearGroups.map(
        group => group.yearGroupId
      );
      // Filter out newly added year groups from fields, as their ids will be the name of the newly created year group
      const existingYearGroupIds = fields.yearGroupIds.filter(
        ygId => !newlyCreatedYearGroups.some(nyg => nyg.name === ygId)
      );

      const response = await Api.createGroup(schoolId, {
        code: code,
        teacherIds: [teacherId],
        studentIds: [],
        yearGroupIds: uniq(
          compact([...existingYearGroupIds, ...newYearGroupIds])
        )
      });

      tracking.track(tracking.eventNames.ClassCreationFinished, {
        group_id: response?.groupId,
        classroom_group_id: response?.groupId,
        name: response?.code
      });
      analytics.intercom.set({
        last_class_created_at: moment.utc().unix()
      });

      dispatch(clearFields());
      await loadUserContext(schoolId);
      toaster.success('Class created');
      navigate(`/${schoolId}/classes/${response?.groupId}/switch`);
    } catch (e) {
      console.error(e);
      toaster.danger('Failed to create class');
    } finally {
      setLoading(false);
    }
  }, [
    code,
    createYearGroups,
    dispatch,
    fields.yearGroupIds,
    navigate,
    schoolId,
    teacherId,
    loadUserContext
  ]);

  if (loadingData) {
    return <Loading />;
  }

  const yearGroupOptions = [...yearGroups, ...newlyAddedGroups].map(
    yearGroup => ({
      value: yearGroup.yearGroupId,
      label: yearGroup.name
    })
  );

  return (
    <>
      <FormValidator
        rules={{
          code: value => !value && 'Please enter a Class name',
          yearGroupIds: value =>
            !(value.length > 0) && 'Please choose a year group'
        }}
        fields={fields}>
        {(errors, validate) => (
          <>
            <Card.Title>
              <h1>Class details</h1>
            </Card.Title>
            <Card className="mb-3">
              <Card.Body>
                <Form.Group>
                  <Input
                    disabled
                    error={errors.code}
                    value={code}
                    label="Class name"
                    placeholder="Class name"
                  />
                </Form.Group>
                <Form.Group>
                  <Select
                    isMulti
                    isCreatable
                    label="Year Groups"
                    options={yearGroupOptions}
                    value={yearGroupOptions.filter(
                      y =>
                        fields.yearGroupIds &&
                        fields.yearGroupIds.includes(y.value)
                    )}
                    onChange={options => {
                      const newItem = options[options.length - 1];

                      if (newItem && newItem.__isNew__) {
                        addNewYearGroup(newItem);
                      } else {
                        handleYearGroupSelect(options.map(o => o.value));
                      }
                    }}
                    error={errors.yearGroupIds}
                  />
                </Form.Group>
                <Button
                  color="primary"
                  onClick={async () => {
                    const valid = validate();
                    if (valid) {
                      await tracking.track(
                        tracking.eventNames.ClassDetailsAdded,
                        {
                          year_groups: JSON.stringify(fields?.yearGroupIds),
                          name: code
                        }
                      );
                      addGroup();
                    }
                  }}
                  loading={loading}
                  block>
                  Create class
                </Button>
              </Card.Body>
            </Card>
            <Button color="link" onClick={searchAgain}>
              <Icon name="arrowLeft" color="primary" />
              Search again
            </Button>
          </>
        )}
      </FormValidator>
    </>
  );
};

export default ClassAddForm;
