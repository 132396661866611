import styled from 'styled-components';
import PaginationControls from '../PaginationControls';
import pluralize from '@/helpers/pluralizeString';

export const TablePaginationControls = ({
  pageIndex,
  setPageIndex,
  pageSize,
  totalItems,
  pageCount,
  canNextPage,
  canPreviousPage,
  ...props
}) => {
  const firstDisplayedItemIndex = pageIndex * pageSize;
  return (
    <Wrapper {...props}>
      <span className="text-sm text-muted">
        {totalItems < pageSize ? (
          <>
            <strong>{totalItems}</strong> {pluralize('result', totalItems)}
          </>
        ) : (
          <>
            <strong>
              {firstDisplayedItemIndex + 1}&ndash;
              {Math.min(firstDisplayedItemIndex + pageSize, totalItems)}
            </strong>{' '}
            of <strong>{totalItems}</strong> results
          </>
        )}
      </span>
      <div className="d-flex align-items-center">
        <PaginationControls
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: 1px solid var(--border-color);
`;

export default TablePaginationControls;
