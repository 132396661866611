import { FLOW_API_URL } from '@/config/settings';
import Client from './Client';

const getApiUri = uri => getUri(FLOW_API_URL, uri);

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, '/');
  return `${endpoint}${formattedUri}`;
};

class FlowApi {
  static async StartTeacherConversation(input, context) {
    return Client.post(getApiUri(`v1/conversations/teachers`), {
      input,
      context
    });
  }

  static async GetTeacherConversation(conversationId) {
    return Client.get(getApiUri(`v1/conversations/teachers/${conversationId}`));
  }

  static async SendTeacherConversationMessage(conversationId, input, context) {
    return Client.put(
      getApiUri(`v1/conversations/teachers/${conversationId}`),
      {
        input,
        context
      }
    );
  }

  static async GetFlowGeneratorSession(flowGeneratorSessionId, token = null) {
    return Client.get(
      getApiUri(`v1/flow-generator-sessions/${flowGeneratorSessionId}`),
      {},
      { token }
    );
  }

  static async UpdateFlowGeneratorSession(
    flowGeneratorSessionId,
    input,
    token = null
  ) {
    return Client.post(
      getApiUri(`v1/flow-generator-sessions/${flowGeneratorSessionId}`),
      input,
      { token }
    );
  }

  static async CreateGeneralFlowGeneratorSession(
    userId,
    templateSlug,
    request = {},
    token = null
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/flow-templates/${templateSlug}/flow-generator-sessions`
      ),
      request,
      { token }
    );
  }

  static async CreateFlowGeneratorSessionForTopicPathwayQuiz(
    userId,
    topicPathwayQuizId,
    request,
    token = null
  ) {
    return Client.post(
      getApiUri(
        `/v1/users/${userId}/topic-pathway-quizs/${topicPathwayQuizId}/flow-generator-sessions`
      ),
      request,
      { token }
    );
  }

  static async GetFlowGeneratorSessionForQuizSession(
    quizSessionId,
    token = null
  ) {
    return Client.get(
      getApiUri(`v1/quiz-sessions/${quizSessionId}/flow-generator-sessions`),
      {},
      { token }
    );
  }
}

export default FlowApi;
