import { ProgressBar } from '@/components/common';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const SidebarItem = ({
  children,
  progress = null,
  active = false,
  ...props
}) => (
  <Item active={active} {...props}>
    {children}
    {progress !== null ? (
      <ProgressBar
        color={active ? 'indigo' : 'indigo-light'}
        currentIndex={progress}
        total={1}
        style={{ height: '0.25rem' }}
        className="mt-1"
      />
    ) : null}
  </Item>
);

const Item = styled(NavLink)`
  display: block;
  border-radius: var(--border-radius);
  padding: 0.75rem;
  color: var(--black);
  font-weight: 400;
  ${props =>
    props.active &&
    `
    background-color: var(--blue-lighter);
    color: var(--indigo);
    font-weight: 500;
  `};

  &.active {
    background-color: var(--blue-lighter);
    color: var(--indigo);
    font-weight: 500;
  }

  ${props =>
    props.disabled &&
    `
    color: var(--gray);
    cursor: not-allowed;
    pointer-events: none;
  `};
`;
export default SidebarItem;
