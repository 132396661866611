import styled from 'styled-components';
import { Loading } from '@/components/common';
import { useState } from 'react';
import useInterval from '@/hooks/useInterval';
import { useNavigate } from 'react-router';

const LoadingPage = () => {
  const navigate = useNavigate();
  const pollIntervalMs = 5000;
  const [state, setState] = useState({
    isShowLogout: false,
    isLogout: false,
    period: 0
  });
  useInterval(() => {
    setState(() => ({
      ...state,
      isShowLogout: true,
      period: state.period + pollIntervalMs
    }));
    if (state.period > 120000) {
      navigate('/logout');
    }
  }, pollIntervalMs);

  return (
    <Message>
      <div className="text-center">
        <Loading />
        {state.isShowLogout && (
          <>
            <h3>Loading...</h3>
            <div>
              <p>Taking too long?</p>
              <p>
                Click{' '}
                <a href="/logout" aria-label="logout">
                  here
                </a>{' '}
                to logout and login again.
              </p>
            </div>
          </>
        )}
      </div>
    </Message>
  );
};
const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export default LoadingPage;
