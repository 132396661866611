import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '@/styles';

const PlaceholderBar = ({
  height,
  length,
  marginBottom,
  marginTop,
  long,
  fullLength,
  color,
  notAnimated = false
}) => (
  <Bar
    height={height}
    length={length}
    marginBottom={marginBottom}
    marginTop={marginTop}
    long={long}
    fullLength={fullLength}
    color={color}
    notAnimated={notAnimated}
  />
);

export default PlaceholderBar;

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1}
  100% { opacity: 0.6; }
`;

const Bar = styled.div`
  height: ${({ height }) => {
    if (height) {
      return height;
    }
    return '0.8rem';
  }};
  margin-bottom: ${({ marginBottom }) => {
    if (marginBottom) {
      return marginBottom;
    }
    return '0.8rem';
  }};
  margin-top: ${({ marginTop }) => {
    if (marginTop) {
      return marginTop;
    }
    return '0';
  }};
  border-radius: 4px;
  background: ${({ color }) =>
    (color && colors[color]) || 'var(--gray-lighter)'};
  max-width: 100%;
  width: ${({ long, fullLength, length }) => {
    if (length) {
      return length;
    } else if (long) {
      return '13rem';
    } else if (fullLength) {
      return '100%';
    }
    return '7rem';
  }};
  animation: ${pulse} 2s infinite;

  ${props => props.notAnimated && 'animation: unset;'}
`;
