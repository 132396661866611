import { Page } from '@/components/common';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import SidebarItem from '@/components/SidebarItem';
import { Suspense } from 'react';

const MotivatePage = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  return (
    <Page>
      <Page.Sidebar>
        <h3>Motivate</h3>
        <SidebarItem to={`/${schoolId}/motivate`} end>
          Overview
        </SidebarItem>
        <SidebarItem to={`/${schoolId}/motivate/school-league`}>
          School League
        </SidebarItem>
        <SidebarItem to={`/${schoolId}/motivate/group-league`}>
          Class League
        </SidebarItem>
        <SidebarItem to={`/${schoolId}/motivate/class-league`}>
          Student League
        </SidebarItem>
      </Page.Sidebar>
      <Page.Main>
        <Suspense>
          <Outlet />
        </Suspense>
      </Page.Main>
    </Page>
  );
};

export default MotivatePage;
