import joinStatuses from '@/constants/joinStatuses';
import { v1 } from 'uuid';

const defaultGroupFields = {
  code: '',
  description: '',
  departmentName: '',
  departmentId: null,
  yearGroupIds: [],
  newlyAddedGroups: []
};

const initialState = {
  groupFields: defaultGroupFields,
  teachers: [],
  students: [],
  studentsToCreate: [],
  joinStatus: null,
  groupIsDirty: false,
  teachersIsDirty: false,
  studentsIsDirty: false
};

const joinClassStepper = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_JOIN_CLASS_STATUS':
      return {
        ...state,
        joinStatus: action.status,
        error: action.error
      };
    case 'CHANGE_JOIN_CLASS_SCHOOL_FIELD':
      return {
        ...state,
        groupIsDirty: true,
        groupFields: {
          ...state.groupFields,
          [action.name]: action.value
        }
      };
    case 'JOIN_CLASS_STEPPER_STUDENT_ADD': {
      const newStudent = {
        ...action.student,
        studentId: v1(),
        shouldCreate: true
      };

      return {
        ...state,
        studentsIsDirty: true,
        students: [...state.students, newStudent],
        studentsToCreate: [...state.studentsToCreate, newStudent]
      };
    }
    case 'JOIN_CLASS_STEPPER_STUDENT_UPDATE': {
      const { studentId } = action.student;

      const matchingStudentIndex = state.students.findIndex(
        student => student.studentId === studentId
      );

      const nextStudents = [...state.students];

      if (matchingStudentIndex > -1) {
        nextStudents[matchingStudentIndex] = {
          ...action.student
        };
      }

      return {
        ...state,
        studentsIsDirty: true,
        students: nextStudents
      };
    }
    case 'JOIN_CLASS_STEPPER_STUDENT_TOGGLE': {
      const { studentId } = action.student;

      const matchingStudentIndex = state.students.findIndex(
        student => student.studentId === studentId
      );

      const nextStudents = [...state.students];

      if (matchingStudentIndex > -1) {
        nextStudents.splice(matchingStudentIndex, 1);
      } else {
        nextStudents.push({
          ...action.student
        });
      }

      return {
        ...state,
        studentsIsDirty: true,
        students: nextStudents
      };
    }
    case 'JOIN_CLASS_STEPPER_POPULATE_TEACHERS':
      return {
        ...state,
        teachers: action.teachers.reduce(
          (acc, val) => ({ ...acc, [val.teacherId]: val }),
          {}
        )
      };
    case 'JOIN_CLASS_STEPPER_TEACHER_ADD': {
      const { email, firstName, lastName, teacherId } = action.teacher;
      const currentTeacher = teacherId ? state.teachers[teacherId] : null;
      const id = teacherId || v1();

      return {
        ...state,
        teachersIsDirty: true,
        teachers: {
          ...state.teachers,
          [id]: {
            firstName,
            lastName,
            email,
            teacherId: id,
            selected: currentTeacher ? currentTeacher.selected : true,
            shouldCreate: true
          }
        }
      };
    }
    case 'JOIN_CLASS_STEPPER_TEACHER_TOGGLE': {
      const chosenTeacher = state.teachers[action.teacherId];
      return {
        ...state,
        teachersIsDirty: true,
        teachers: {
          ...state.teachers,
          [action.teacherId]: {
            ...chosenTeacher,
            selected: !chosenTeacher.selected
          }
        }
      };
    }
    case 'JOIN_CALSS_CLEAR_IS_DIRTY_STATUSES': {
      return {
        ...state,
        teachersIsDirty: false,
        studentsIsDirty: false,
        groupIsDirty: false
      };
    }
    case 'PREPARE_TO_JOIN_CLASS':
      return {
        ...state,
        groupFields: action.fields,
        joinStatus: joinStatuses.willJoin
      };
    case 'PREPARE_TO_ADD_CLASS':
      return {
        ...state,
        groupFields: {
          ...state.groupFields,
          code: action.code
        },
        joinStatus: joinStatuses.willAdd
      };
    case 'JOIN_CLASS_STEPPER_SCHOOL_CLEAR':
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default joinClassStepper;
