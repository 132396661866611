import styled from 'styled-components';
import { colors } from '@/styles';

const EmptyState = ({ title, message, ...props }) => (
  <Wrapper {...props}>
    <Content>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1376 710">
        <defs>
          <path
            id="a"
            d="M1215 709H246c-318.23 0-351.741-599.425 42-694 393.605-93.23 465.614 275.32 736 222 407.434-78.752 447.367 472 191 472z"></path>
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(0 1)">
          <mask id="b" fill={colors.white}>
            <use xlinkHref="#a"></use>
          </mask>
          <use fill={colors.grayLighter} xlinkHref="#a"></use>
          <path
            fill={colors.grayLighter}
            d="M-27 293h456c5.523 0 10 4.477 10 10v456c0 5.523-4.477 10-10 10H-27c-5.523 0-10-4.477-10-10V303c0-5.523 4.477-10 10-10zm761 216h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H734c-13.807 0-25-11.193-25-25s11.193-25 25-25zm10-230h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H744c-13.807 0-25-11.193-25-25s11.193-25 25-25zm-10 310h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H734c-13.807 0-25-11.193-25-25s11.193-25 25-25zm10-230h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H744c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0-310h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H744c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h236c13.807 0 25 11.193 25 25s-11.193 25-25 25H744c-13.807 0-25-11.193-25-25s11.193-25 25-25z"
            mask="url(#b)"></path>
          <circle
            cx="584"
            cy="114"
            r="95"
            fill={colors.grayLight}
            mask="url(#b)"></circle>
          <circle
            cx="584"
            cy="344"
            r="95"
            fill={colors.grayLight}
            mask="url(#b)"></circle>
          <circle
            cx="584"
            cy="574"
            r="95"
            fill={colors.grayLight}
            mask="url(#b)"></circle>
          <path
            fill={colors.grayLight}
            d="M-27-31h456c5.523 0 10 4.477 10 10v274c0 5.523-4.477 10-10 10H-27c-5.523 0-10-4.477-10-10V-21c0-5.523 4.477-10 10-10zm1082 454h456c5.523 0 10 4.477 10 10v302c0 5.523-4.477 10-10 10h-456c-5.523 0-10-4.477-10-10V433c0-5.523 4.477-10 10-10z"
            mask="url(#b)"></path>
          <path
            fill={colors.grayLight}
            d="M1055 33h456c5.523 0 10 4.477 10 10v340c0 5.523-4.477 10-10 10h-456c-5.523 0-10-4.477-10-10V43c0-5.523 4.477-10 10-10zM-10 343h374c13.807 0 25 11.193 25 25s-11.193 25-25 25H-10c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h374c13.807 0 25 11.193 25 25s-11.193 25-25 25H-10c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h374c13.807 0 25 11.193 25 25s-11.193 25-25 25H-10c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h374c13.807 0 25 11.193 25 25s-11.193 25-25 25H-10c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h374c13.807 0 25 11.193 25 25s-11.193 25-25 25H-10c-13.807 0-25-11.193-25-25s11.193-25 25-25zM30 3h334c13.807 0 25 11.193 25 25s-11.193 25-25 25H30C16.193 53 5 41.807 5 28S16.193 3 30 3zm0 80h334c13.807 0 25 11.193 25 25s-11.193 25-25 25H30c-13.807 0-25-11.193-25-25s11.193-25 25-25zm0 80h334c13.807 0 25 11.193 25 25s-11.193 25-25 25H30c-13.807 0-25-11.193-25-25s11.193-25 25-25z"
            mask="url(#b)"></path>
        </g>
      </Svg>
      {title && <h2>{title}</h2>}
      {message && <h3 className="subtitle">{message}</h3>}
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border-radius: var(--border-radius);
  background-color: var(--white);
`;

const Content = styled.div`
  text-align: center;
  width: 24rem;
  img {
    width: 200px;
    height: auto;
    margin-bottom: 2rem;
  }
`;

const Svg = styled.svg`
  width: 14rem;
  margin-bottom: 2rem;
`;

export default EmptyState;
