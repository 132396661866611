import React, { Component } from 'react';
import styled from 'styled-components';

import { colors } from '@/styles';
import IconNames from './IconNames';

const Svg = styled.svg`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ backgroundColor }) => colors[backgroundColor]};
`;

const StyledSVG = ({ size, backgroundColor, children }) => (
  <Svg
    size={size}
    viewBox="0 0 24 24"
    backgroundColor={backgroundColor}
    version="1.1"
    xmlns="//www.w3.org/2000/svg">
    {children}
  </Svg>
);

class Icon extends Component {
  static defaultProps = {
    color: 'gray6',
    backgroundColor: 'transparent',
    size: 24
  };

  props;

  renderIcon = ({ color, name }) => {
    const fill = colors[color] || 'currentColor';

    switch (name) {
      case 'apple':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M17.84559,18.5 C17.01559,19.74 16.13559,20.95 14.79559,20.97 C13.45559,21 13.02559,20.18 11.50559,20.18 C9.97558999,20.18 9.50558999,20.95 8.23558999,21 C6.92558999,21.05 5.93558999,19.68 5.09558999,18.47 C3.38558999,16 2.07558999,11.45 3.83558999,8.39 C4.70558999,6.87 6.26558999,5.91 7.95558999,5.88 C9.23558999,5.86 10.45559,6.75 11.24559,6.75 C12.02559,6.75 13.50559,5.68 15.05559,5.84 C15.70559,5.87 17.52559,6.1 18.69559,7.82 C18.60559,7.88 16.52559,9.1 16.54559,11.63 C16.57559,14.65 19.19559,15.66 19.22559,15.67 C19.19559,15.74 18.80559,17.11 17.84559,18.5 L17.84559,18.5 Z M12.13559,2.5 C12.86559,1.67 14.07559,1.04 15.07559,1 C15.20559,2.17 14.73559,3.35 14.03559,4.19 C13.34559,5.04 12.20559,5.7 11.08559,5.61 C10.93559,4.46 11.49559,3.26 12.13559,2.5 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );
      case 'android':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M6,18 C6,18.55 6.45,19 7,19 L8,19 L8,22.5 C8,23.33 8.67,24 9.5,24 C10.33,24 11,23.33 11,22.5 L11,19 L13,19 L13,22.5 C13,23.33 13.67,24 14.5,24 C15.33,24 16,23.33 16,22.5 L16,19 L17,19 C17.55,19 18,18.55 18,18 L18,8 L6,8 L6,18 Z M3.5,8 C2.67,8 2,8.67 2,9.5 L2,16.5 C2,17.33 2.67,18 3.5,18 C4.33,18 5,17.33 5,16.5 L5,9.5 C5,8.67 4.33,8 3.5,8 Z M20.5,8 C19.67,8 19,8.67 19,9.5 L19,16.5 C19,17.33 19.67,18 20.5,18 C21.33,18 22,17.33 22,16.5 L22,9.5 C22,8.67 21.33,8 20.5,8 Z M15.53,2.16 L16.83,0.86 C17.03,0.66 17.03,0.35 16.83,0.15 C16.63,-0.05 16.32,-0.05 16.12,0.15 L14.64,1.63 C13.85,1.23 12.95,1 12,1 C11.04,1 10.14,1.23 9.34,1.63 L7.85,0.15 C7.65,-0.05 7.34,-0.05 7.14,0.15 C6.94,0.35 6.94,0.66 7.14,0.86 L8.45,2.17 C6.97,3.26 6,5.01 6,7 L18,7 C18,5.01 17.03,3.25 15.53,2.16 L15.53,2.16 Z M10,5 L9,5 L9,4 L10,4 L10,5 Z M15,5 L14,5 L14,4 L15,4 L15,5 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );
      case 'archive':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M19,9 L15,9 L15,3 L9,3 L9,9 L5,9 L12,16 L19,9 Z M5,18 L5,20 L19,20 L19,18 L5,18 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );
      case 'add':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                points="13 5 11 5 11 11 5 11 5 13 11 13 11 19 13 19 13 13 19 13 19 11 13 11"
              />
            </g>
          </g>
        );

      case 'addFilled':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M13,7 L11,7 L11,11 L7,11 L7,13 L11,13 L11,17 L13,17 L13,13 L17,13 L17,11 L13,11 L13,7 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'addOutline':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M7,11 L7,13 L17,13 L17,11 L7,11 Z M11,17 L13,17 L13,7 L11,7 L11,17 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'arrowLeft':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                points="12 4 10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12"
              />
            </g>
          </g>
        );

      case 'arrowRight':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                points="12 4 10.59 5.41 16.17 11 4 11 4 13 16.17 13 10.59 18.59 12 20 20 12"
              />
            </g>
          </g>
        );

      case 'attention':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M15.73,3 L8.27,3 L3,8.27 L3,15.73 L8.27,21 L15.73,21 L21,15.73 L21,8.27 L15.73,3 Z M12,17.3 C11.28,17.3 10.7,16.72 10.7,16 C10.7,15.28 11.28,14.7 12,14.7 C12.72,14.7 13.3,15.28 13.3,16 C13.3,16.72 12.72,17.3 12,17.3 Z M13,13 L11,13 L11,7 L13,7 L13,13 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'back':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polyline
                stroke={fill}
                strokeWidth="2"
                points="15 5 9 12 15 19"
              />
            </g>
          </g>
        );

      case 'forward':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                transform="translate(13.278911, 12.000000) scale(-1, 1) translate(-13.278911, -12.000000) "
                points="17.3170778 5.65079137 15.7985646 4.34920863 9.2407434 12 15.7985646 19.6507914 17.3170778 18.3492086 11.874899 12"
              />
            </g>
          </g>
        );

      case 'bin':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M17.999,7 L5.999,7 L5.999,19 C5.999,20.104 6.895,21 8,21 L15.999,21 C17.105,21 17.999,20.104 17.999,19 L17.999,7 M14.499,2.999 L9.499,2.999 L8.5,4 L5.999,4 C5.448,4 5,4.448 5,4.999 L5,6 L19,6 L19,4.999 C19,4.448 18.552,4 17.999,4 L15.5,4 L14.499,2.999"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'calendar':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M9,11 L7,11 L7,13 L9,13 L9,11 Z M13,11 L11,11 L11,13 L13,13 L13,11 Z M17,11 L15,11 L15,13 L17,13 L17,11 Z M19,4 L18,4 L18,2 L16,2 L16,4 L8,4 L8,2 L6,2 L6,4 L5,4 C3.89,4 3.01,4.9 3.01,6 L3,20 C3,21.1 3.89,22 5,22 L19,22 C20.1,22 21,21.1 21,20 L21,6 C21,4.9 20.1,4 19,4 Z M19,20 L5,20 L5,9 L19,9 L19,20 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'camera':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle fill={fill} fillRule="nonzero" cx="12" cy="13" r="3.2" />
              <path
                d="M9,3 L7.17,5 L4,5 C2.9,5 2,5.9 2,7 L2,19 C2,20.1 2.9,21 4,21 L20,21 C21.1,21 22,20.1 22,19 L22,7 C22,5.9 21.1,5 20,5 L16.83,5 L15,3 L9,3 Z M12,18 C9.24,18 7,15.76 7,13 C7,10.24 9.24,8 12,8 C14.76,8 17,10.24 17,13 C17,15.76 14.76,18 12,18 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'checkboxOff':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M19,5 L19,19 L5,19 L5,5 L19,5 L19,5 Z M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L19,3 L19,3 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'checkboxMinus':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M5,3 L19,3 C20.1,3 21,3.9 21,5 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 Z M7,11 L7,13 L17,13 L17,11 L7,11 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'checkboxOn':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M5,3 L19,3 C20.1,3 21,3.9 21,5 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 Z M8.70025681,11.3246479 L7.29534866,12.748106 L11.0044211,16.4088431 L17.709774,9.70730812 L16.2959633,8.29269188 L10.9955789,13.5900582 L8.70025681,11.3246479 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'clock':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle stroke={fill} strokeWidth="2" cx="12" cy="12" r="8" />
              <rect
                fill={fill}
                fillRule="nonzero"
                x="11"
                y="7"
                width="2"
                height="6"
                rx="1"
              />
              <rect
                fill={fill}
                fillRule="nonzero"
                transform="translate(14.000000, 12.000000) rotate(90.000000) translate(-14.000000, -12.000000) "
                x="13"
                y="9"
                width="2"
                height="6"
                rx="1"
              />
            </g>
          </g>
        );

      case 'close':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                points="19 6.4 17.6 5 12 10.6 6.4 5 5 6.4 10.6 12 5 17.6 6.4 19 12 13.4 17.6 19 19 17.6 13.4 12"
              />
            </g>
          </g>
        );

      case 'closeOutline':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.49992 9.99998C2.49992 5.85784 5.85778 2.49998 9.99992 2.49998C14.1421 2.49998 17.4999 5.85784 17.4999 9.99998C17.4999 14.1421 14.1421 17.5 9.99992 17.5C5.85778 17.5 2.49992 14.1421 2.49992 9.99998ZM9.99992 0.833313C4.93731 0.833313 0.833252 4.93737 0.833252 9.99998C0.833252 15.0626 4.93731 19.1666 9.99992 19.1666C15.0625 19.1666 19.1666 15.0626 19.1666 9.99998C19.1666 4.93737 15.0625 0.833313 9.99992 0.833313ZM6.07733 13.9226C5.75189 13.5971 5.75189 13.0695 6.07733 12.7441L8.82141 9.99998L6.07733 7.2559C5.75189 6.93047 5.75189 6.40283 6.07733 6.07739C6.40277 5.75195 6.9304 5.75195 7.25584 6.07739L9.99992 8.82147L12.744 6.07739C13.0694 5.75195 13.5971 5.75195 13.9225 6.07739C14.2479 6.40283 14.2479 6.93047 13.9225 7.2559L11.1784 9.99998L13.9225 12.7441C14.2479 13.0695 14.2479 13.5971 13.9225 13.9226C13.5971 14.248 13.0694 14.248 12.744 13.9226L9.99992 11.1785L7.25584 13.9226C6.9304 14.248 6.40277 14.248 6.07733 13.9226Z"
              fill="#32314F"
            />
          </g>
        );

      case 'contextual':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z M12,20 C10.8954305,20 10,19.1045695 10,18 C10,16.8954305 10.8954305,16 12,16 C13.1045695,16 14,16.8954305 14,18 C14,19.1045695 13.1045695,20 12,20 Z M12,8 C10.8954305,8 10,7.1045695 10,6 C10,4.8954305 10.8954305,4 12,4 C13.1045695,4 14,4.8954305 14,6 C14,7.1045695 13.1045695,8 12,8 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'down':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polyline
                stroke={fill}
                strokeWidth="2"
                transform="translate(12.000000, 12.500000) rotate(-90.000000) translate(-12.000000, -12.500000) "
                points="14.5 7.5 9.5 12.5 14.5 17.5"
              />
            </g>
          </g>
        );

      case 'edit':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.49992 3.33331C2.49992 2.87308 2.87301 2.49998 3.33325 2.49998H9.16659C9.62682 2.49998 9.99992 2.12688 9.99992 1.66665C9.99992 1.20641 9.62682 0.833313 9.16659 0.833313H3.33325C1.95254 0.833313 0.833252 1.9526 0.833252 3.33331V16.6666C0.833252 18.0474 1.95254 19.1666 3.33325 19.1666H16.6666C18.0473 19.1666 19.1666 18.0474 19.1666 16.6666V10.8333C19.1666 10.3731 18.7935 9.99998 18.3333 9.99998C17.873 9.99998 17.4999 10.3731 17.4999 10.8333V16.6666C17.4999 17.1269 17.1268 17.5 16.6666 17.5H3.33325C2.87301 17.5 2.49992 17.1269 2.49992 16.6666V3.33331ZM16.4664 1.07739C16.3101 0.921111 16.0981 0.833313 15.8771 0.833313C15.6561 0.833313 15.4441 0.92111 15.2879 1.07739L5.28786 11.0774C5.18353 11.1817 5.10877 11.3119 5.07122 11.4546L4.19402 14.7879C4.11859 15.0745 4.20108 15.3797 4.41066 15.5892C4.62024 15.7988 4.92536 15.8813 5.212 15.8059L8.54533 14.9287C8.68802 14.8911 8.81818 14.8164 8.92251 14.712L18.9225 4.71204C19.2479 4.38661 19.2479 3.85897 18.9225 3.53353L16.4664 1.07739ZM6.16938 13.8305L6.62567 12.0966L15.8771 2.84516L17.1547 4.12279L7.9033 13.3742L6.16938 13.8305Z"
              fill="#32314F"
            />
          </g>
        );

      case 'eyeOff':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,7 C14.76,7 17,9.24 17,12 C17,12.65 16.87,13.26 16.64,13.83 L19.56,16.75 C21.07,15.49 22.26,13.86 22.99,12 C21.26,7.61 16.99,4.5 11.99,4.5 C10.59,4.5 9.25,4.75 8.01,5.2 L10.17,7.36 C10.74,7.13 11.35,7 12,7 Z M2,4.27 L4.28,6.55 L4.74,7.01 C3.08,8.3 1.78,10.02 1,12 C2.73,16.39 7,19.5 12,19.5 C13.55,19.5 15.03,19.2 16.38,18.66 L16.8,19.08 L19.73,22 L21,20.73 L3.27,3 L2,4.27 Z M7.53,9.8 L9.08,11.35 C9.03,11.56 9,11.78 9,12 C9,13.66 10.34,15 12,15 C12.22,15 12.44,14.97 12.65,14.92 L14.2,16.47 C13.53,16.8 12.79,17 12,17 C9.24,17 7,14.76 7,12 C7,11.21 7.2,10.47 7.53,9.8 L7.53,9.8 Z M11.84,9.02 L14.99,12.17 L15.01,12.01 C15.01,10.35 13.67,9.01 12.01,9.01 L11.84,9.02 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'eyeOn':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'info':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle stroke={fill} strokeWidth="2" cx="12" cy="12" r="9" />
              <text fontSize="14" fontWeight="bold" fill={fill}>
                <tspan x="8" y="17">
                  ?
                </tspan>
              </text>
            </g>
          </g>
        );

      case 'listing':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <rect fill={fill} x="5" y="5" width="6" height="6" rx="1" />
              <rect fill={fill} x="13" y="5" width="6" height="6" rx="1" />
              <rect fill={fill} x="5" y="13" width="6" height="6" rx="1" />
              <rect fill={fill} x="13" y="13" width="6" height="6" rx="1" />
            </g>
          </g>
        );

      case 'mail':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <rect
                stroke={fill}
                strokeWidth="2"
                x="6"
                y="8"
                width="12"
                height="9"
                rx="2"
              />
              <path
                d="M5.99987793,9 L10.4392018,12.5809843 C11.3012068,13.2763214 12.7000047,13.2753212 13.5607347,12.5809843 L17.9998779,9"
                stroke={fill}
                strokeWidth="2"
              />
            </g>
          </g>
        );

      case 'menu':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                points="4 11 4 13 16 13 16 11"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                points="4 16 4 18 10 18 10 16"
              />
              <polygon
                fill={fill}
                fillRule="nonzero"
                points="4 6 4 8 20 8 20 6"
              />
            </g>
          </g>
        );

      case 'message':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M20,2 C21.1,2 22,2.9 22,4 L22,16 C22,17.1 21.1,18 20,18 L6,18 L2,22 L2.01,4 C2.01,2.9 2.9,2 4,2 L20,2 Z M15,9 L15,11 L17,11 L17,9 L15,9 Z M11,9 L11,11 L13,11 L13,9 L11,9 Z M7,9 L7,11 L9,11 L9,9 L7,9 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'minusFilled':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M7,11 L7,13 L17,13 L17,11 L7,11 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'minusOutline':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M7,11 L7,13 L17,13 L17,11 L7,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'phone':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M8.41333333,11.1933333 C9.37333333,13.08 10.92,14.62 12.8066667,15.5866667 L14.2733333,14.12 C14.4533333,13.94 14.72,13.88 14.9533333,13.96 C15.7,14.2066667 16.5066667,14.34 17.3333333,14.34 C17.7,14.34 18,14.64 18,15.0066667 L18,17.3333333 C18,17.7 17.7,18 17.3333333,18 C11.0733333,18 6,12.9266667 6,6.66666667 C6,6.3 6.3,6 6.66666667,6 L9,6 C9.36666667,6 9.66666667,6.3 9.66666667,6.66666667 C9.66666667,7.5 9.8,8.3 10.0466667,9.04666667 C10.12,9.28 10.0666667,9.54 9.88,9.72666667 L8.41333333,11.1933333 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'pin':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,5 C9.23571429,5 7,7.191 7,9.9 C7,13.575 12,19 12,19 C12,19 17,13.575 17,9.9 C17,7.191 14.7642857,5 12,5 L12,5 Z M12,11.65 C11.0142857,11.65 10.2142857,10.866 10.2142857,9.9 C10.2142857,8.934 11.0142857,8.15 12,8.15 C12.9857143,8.15 13.7857143,8.934 13.7857143,9.9 C13.7857143,10.866 12.9857143,11.65 12,11.65 L12,11.65 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'radioButtonOff':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'radioButtonOn':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z"
                fill={fill}
              />
              <circle fill={fill} cx="12" cy="12" r="5" />
            </g>
          </g>
        );

      case 'search':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M15.502,9.491 L14.708,9.491 L14.432,9.765 C15.407,10.902 16,12.376 16,13.991 C16,17.581 13.09,20.491 9.5,20.491 C5.91,20.491 3,17.581 3,13.991 C3,10.401 5.91,7.491 9.5,7.491 C11.115,7.491 12.588,8.083 13.725,9.057 L14.001,8.783 L14.001,7.991 L18.999,3 L20.49,4.491 L15.502,9.491 L15.502,9.491 Z M9.5,9.491 C7.014,9.491 5,11.505 5,13.991 C5,16.476 7.014,18.491 9.5,18.491 C11.985,18.491 14,16.476 14,13.991 C14,11.505 11.985,9.491 9.5,9.491 L9.5,9.491 Z"
                fill={fill}
                transform="translate(11.745000, 11.745500) scale(1, -1) translate(-11.745000, -11.745500) "
              />
            </g>
          </g>
        );

      case 'student':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle stroke={fill} strokeWidth="2" cx="12" cy="8" r="3" />
              <path
                d="M7,19 L7,17 C7,15.8954305 7.88743329,15 8.99961498,15 L15.000385,15 C16.1047419,15 17,15.8877296 17,17 L17,19"
                stroke={fill}
                strokeWidth="2"
              />
            </g>
          </g>
        );

      case 'teacher':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle stroke={fill} strokeWidth="2" cx="12" cy="9" r="3" />
              <path
                d="M6,22 L6,19 C6,17.3431458 7.34162278,16 8.99715203,16 L15.002848,16 C16.6581293,16 18,17.3465171 18,19 L18,22"
                stroke={fill}
                strokeWidth="2"
              />
              <path
                d="M6,3.00317383 L18,3.00317383"
                stroke={fill}
                strokeWidth="2"
              />
            </g>
          </g>
        );

      case 'tick':
        return (
          <g stroke={fill} strokeWidth="2" fill="none" fillRule="evenodd">
            <polyline points="20 6 9 17 4 12"></polyline>
          </g>
        );

      case 'upload':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M9,17 L15,17 L15,11 L19,11 L12,4 L5,11 L9,11 L9,17 Z M5,19 L19,19 L19,21 L5,21 L5,19 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'users':
        return (
          <g
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </g>
        );

      case 'web':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <circle stroke={fill} strokeWidth="2" cx="12" cy="12" r="5" />
              <path
                d="M10.5,7.5 L10.5,16.5553851"
                stroke={fill}
                strokeLinecap="square"
              />
              <path
                d="M13.5,7.5 L13.5,16.5553851"
                stroke={fill}
                strokeLinecap="square"
              />
              <path
                d="M7.5,10.5 L16.5,10.5"
                stroke={fill}
                strokeLinecap="square"
              />
              <path
                d="M7.5,13.5 L16.5,13.5"
                stroke={fill}
                strokeLinecap="square"
              />
            </g>
          </g>
        );

      case 'locker':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect
              fillOpacity="0"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="24"
              height="24"
            />
            <g fillRule="nonzero" fill={fill}>
              <path d="M12,17 C13.1,17 14,16.1 14,15 C14,13.9 13.1,13 12,13 C10.9,13 10,13.9 10,15 C10,16.1 10.9,17 12,17 Z M18,8 L17,8 L17,6 C17,3.24 14.76,1 12,1 C9.24,1 7,3.24 7,6 L7,8 L6,8 C4.9,8 4,8.9 4,10 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,10 C20,8.9 19.1,8 18,8 Z M8.9,6 C8.9,4.29 10.29,2.9 12,2.9 C13.71,2.9 15.1,4.29 15.1,6 L15.1,8 L8.9,8 L8.9,6 Z M18,20 L6,20 L6,10 L18,10 L18,20 Z" />
            </g>
          </g>
        );

      case 'link':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M11,5 L7,5 C5.8954305,5 5,5.8954305 5,7 L5,17 C5,18.1045695 5.8954305,19 7,19 L17,19 C18.1045695,19 19,18.1045695 19,17 L19,13 L17,13 L17,17 L7,17 L7,7 L11,7 L11,5 Z"
                fill={fill}
                fillRule="nonzero"
              />
              <polygon
                fill={fill}
                points="13 5 13 7 15.59 7 9.76 12.83 11.17 14.24 17 8.41 17 11 19 11 19 5"
              />
            </g>
          </g>
        );

      case 'thumbsUp':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M10,10 L17.5535866,10 C18.6600741,10 19.2556466,10.8410197 18.8848477,11.8756446 L16.6899416,18 L11.0015519,18 C10.44841,18 10,17.5469637 10,17.0029699 L10,10 Z M13.8228238,6 L14.7785297,6.8 L14.1458714,8.9183364 L13.8228238,10 L10,10 L13.8228238,6 Z M7,10 L9,10 L9,18 L7,18 L7,10 Z"
                fill={fill}
              />
            </g>
          </g>
        );

      case 'thumbsDown':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M9,11 L16.5535866,11 C17.6600741,11 18.2556466,11.8410197 17.8848477,12.8756446 L15.6899416,19 L10.0015519,19 C9.44841004,19 9,18.5469637 9,18.0029699 L9,11 Z M12.8228238,7 L13.7785297,7.8 L13.1458714,9.9183364 L12.8228238,11 L9,11 L12.8228238,7 Z M6,11 L8,11 L8,19 L6,19 L6,11 Z"
                fill={fill}
                transform="translate(11.997506, 13.000000) scale(1, -1) translate(-11.997506, -13.000000) "
              />
            </g>
          </g>
        );

      case 'video':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M4,6 L4,18 L20,18 L20,6 L4,6 Z"
                stroke={fill}
                strokeWidth="2"
              />
              <path
                d="M11,10.3969808 L11,13.5786485 L13.573049,11.9878146 L11,10.3969808 Z"
                stroke={fill}
                strokeWidth="2"
              />
            </g>
          </g>
        );

      case 'spreadsheet':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <rect fill={fill} x="4" y="6" width="4" height="2" rx="1" />
              <rect fill={fill} x="4" y="16" width="4" height="2" rx="1" />
              <rect fill={fill} x="4" y="11" width="4" height="2" rx="1" />
              <rect fill={fill} x="10" y="6" width="10" height="2" rx="1" />
              <rect fill={fill} x="10" y="16" width="10" height="2" rx="1" />
              <rect fill={fill} x="10" y="11" width="10" height="2" rx="1" />
            </g>
          </g>
        );

      case 'invoice':
        return (
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
              <rect
                id="Area"
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M7,10 L7,10 L7,10 C7,10.5522847 7.44771525,11 8,11 L16,11 L16,11 C16.5522847,11 17,10.5522847 17,10 L17,10 L17,10 C17,9.44771525 16.5522847,9 16,9 L8,9 L8,9 C7.44771525,9 7,9.44771525 7,10 Z"
                fill={fill}
              />
              <path
                d="M7,14 L7,14 L7,14 C7,14.5522847 7.44771525,15 8,15 L12,15 L12,15 C12.5522847,15 13,14.5522847 13,14 L13,14 L13,14 C13,13.4477153 12.5522847,13 12,13 L8,13 L8,13 C7.44771525,13 7,13.4477153 7,14 Z"
                fill={fill}
              />
              <path
                d="M5,5 L5,19 L19,19 L19,5 L5,5 Z M5,3 L19,3 C20.1,3 21,3.9 21,5 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,5 C3,3.9 3.9,3 5,3 Z"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        );

      case 'misconceptions':
        return (
          <g
            stroke={fill}
            strokeWidth={2}
            strokeLinecap="round"
            fill="none"
            strokeLinejoin="round">
            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
          </g>
        );

      case 'settings':
        return (
          <g
            stroke={fill}
            fill="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M10.3246 4.31731C10.751 2.5609 13.249 2.5609 13.6754 4.31731C13.9508 5.45193 15.2507 5.99038 16.2478 5.38285C17.7913 4.44239 19.5576 6.2087 18.6172 7.75218C18.0096 8.74925 18.5481 10.0492 19.6827 10.3246C21.4391 10.751 21.4391 13.249 19.6827 13.6754C18.5481 13.9508 18.0096 15.2507 18.6172 16.2478C19.5576 17.7913 17.7913 19.5576 16.2478 18.6172C15.2507 18.0096 13.9508 18.5481 13.6754 19.6827C13.249 21.4391 10.751 21.4391 10.3246 19.6827C10.0492 18.5481 8.74926 18.0096 7.75219 18.6172C6.2087 19.5576 4.44239 17.7913 5.38285 16.2478C5.99038 15.2507 5.45193 13.9508 4.31731 13.6754C2.5609 13.249 2.5609 10.751 4.31731 10.3246C5.45193 10.0492 5.99037 8.74926 5.38285 7.75218C4.44239 6.2087 6.2087 4.44239 7.75219 5.38285C8.74926 5.99037 10.0492 5.45193 10.3246 4.31731Z" />
            <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
          </g>
        );

      case 'filter':
        return (
          <g stroke={fill} fill="none" strokeWidth="1">
            <circle fill={fill} fillRule="nonzero" cx="7" cy="7" r="2" />
            <circle fill={fill} fillRule="nonzero" cx="15" cy="17" r="2" />
            <line x1="2" y1="7" x2="20" y2="7" />
            <line x1="2" y1="17" x2="20" y2="17" />
          </g>
        );

      default:
        return null;
    }
  };

  render() {
    const {
      color,
      backgroundColor = 'transparent',
      size = 24,
      name
    } = this.props;

    const icon = this.renderIcon({
      color,
      backgroundColor,
      size,
      name
    });

    if (Object.keys(IconNames).indexOf(name) === -1) {
      return null;
    }

    return (
      <StyledSVG color={color} backgroundColor={backgroundColor} size={size}>
        {icon}
      </StyledSVG>
    );
  }
}

export default Icon;
