import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import analytics from '@/analytics';
import { toaster } from '@/components/common';
import { GRAPHQL_URL, getAuthToken } from './settings';
import idFields from '@/constants/idFields';
import { store } from '../store';
import { logout } from '../actions/auth';

const httpLink = new HttpLink({
  uri: GRAPHQL_URL
});

// eslint-disable-next-line no-unused-vars
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? token : ''
    }
  };
});

const gaLink = new ApolloLink((operation, forward) => {
  const category = mapRequestToCategory(operation.operationName);
  if (operation.operationName && category) {
    analytics.track(operation.operationName, {
      category: 'GraphQL Query'
    });
  }
  return forward(operation);
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      // Parse the body property, which can contain sensitive information, and don't add properties
      // to be recorded in sentry that match a preset list of forbidden property names.
      // The below will capture things like "firstName", "schemeName" etc...
      if (error.body) {
        try {
          const forbiddenPropertiesRegex =
            /email|password|name|title|comment/gi;
          const body = JSON.parse(error.body);
          const parsedBody = {};
          for (let prop in body) {
            if (body.hasOwnProperty(prop)) {
              if (!prop.toLowerCase().match(forbiddenPropertiesRegex)) {
                parsedBody[prop] = body[prop];
              }
            }
          }
          error.body = JSON.stringify(parsedBody);
        } catch (err) {
          // Wrapped in a try catch as we don't want to risk not logging an error in sentry at all.
          console.error('Error parsing body property for data security!', err);
        }
      }

      const { message, status } = error;

      console.error(message, error);
      //analytics.trackError(new Error(`[GraphQL error]: ${message}`));

      if (status === 401) {
        store.dispatch(logout());
      } else {
        const modelErrors = Object.keys(error.modelState || {});
        if (modelErrors.length) {
          modelErrors.forEach(x => {
            let e = error.modelState[x];

            // The old MVC API returns an array, so here we just grab the first item (error message) in that array.
            if (Array.isArray(e)) {
              e = e[0];
            }

            if (e.trim()) {
              toaster.danger(e);
            }
          });
        } else {
          toaster.danger(message);
        }
      }
    });
  }

  if (networkError) {
    console.error(networkError);
    //analytics.trackError(new Error(`[Network error]: ${networkError}`));
    toaster.danger(
      'Something went wrong while trying to do that. Please refresh your browser and try again.',
      {
        id: 'network-error'
      }
    );
  }
});

const link = ApolloLink.from([
  authLink,
  gaLink,
  errorLink,
  //stateLink,
  httpLink
]);

// Apollo likes unique IDs to be called id, we often call it something else like schoolId, so map everything to id
const cache = new InMemoryCache({
  dataIdFromObject: o => {
    const cacheItem = idFields[o.__typename]
      ? `${o.__typename}_${idFields[o.__typename](o)}`
      : undefined;
    return cacheItem;
  }
});

const client = new ApolloClient({
  cache,
  link
});

export default client;

const mapRequestToCategory = operationName => {
  if (!operationName) return null;
  if (categoriesMap.hasOwnProperty(operationName))
    return categoriesMap[operationName];
  return null;
};

const USER = 'User';
const SCHOOL = 'School';
const CLASS = 'Class';
const SCHEME = 'Scheme of Work';
const NOTIFICATIONS = 'Notifications';

const categoriesMap = {
  dismissNotification: NOTIFICATIONS,
  createClonedScheme: SCHEME,
  assignScheme: SCHEME,
  createTopicUnit: SCHEME,
  deleteHolidayUnit: SCHEME,
  deleteScheme: SCHEME,
  deleteTopicUnit: SCHEME,
  updateScheme: SCHEME,
  updateSchemeTopicUnits: SCHEME,
  addGroup: CLASS,
  updateStudent: CLASS,
  updateTeacher: CLASS,
  deleteGroup: CLASS,
  joinGroup: CLASS,
  moveStudents: CLASS,
  updateGroup: CLASS,
  updateGroupStudents: CLASS,
  updateGroupTeachers: CLASS,
  addSchool: SCHOOL,
  createDepartments: SCHOOL,
  joinSchool: SCHOOL,
  leaveSchool: SCHOOL,
  verifyTeacher: SCHOOL,
  deleteTeacher: SCHOOL,
  login: USER,
  forgotPassword: USER,
  loginUserWithGoogle: USER,
  updateUserWithInvitation: USER
};
