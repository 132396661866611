import { useMemo } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import TableRows from './TableRows';
import Header from './Header';
import DataTableHorizontalScroll from './DataTableHorizontalScroll';
import withLoadingOverlay from '@/hocs/withLoadingOverlay';

const TableWithHeader = ({
  rows,
  prepareRow,
  setRowSelectedState,
  selectedRowIds,
  headerContent,
  footerContent,
  enableBulkSelection,
  headerGroups,
  getTableProps,
  getTableBodyProps,
  emptyState,
  ...props
}) => {
  const rowIds = useMemo(() => rows.map(row => row.id), [rows]);
  const isAllSelected = useMemo(() => {
    return rowIds.length > 0 && rowIds.every(x => selectedRowIds.includes(x));
  }, [rowIds, selectedRowIds]);

  const colGroups = useMemo(() => {
    const flatHeaders = headerGroups.flatMap(g => g.headers);
    const totalWidthRatio = flatHeaders.reduce(
      (prev, h) => prev + (h.colWidthRatio || 1),
      0
    );

    return flatHeaders.map((h, i) => {
      let calculatedWidth = h.colWidth ?? 'auto';
      if (h.colWidthRatio !== undefined) {
        calculatedWidth = `${(h.colWidthRatio / totalWidthRatio) * 100}%`;
      }
      if (h.minColWidth !== undefined) {
        if (calculatedWidth === 'auto') {
          calculatedWidth = h.minColWidth;
        } else {
          calculatedWidth = `max(${h.minColWidth}, ${calculatedWidth})`;
        }
      }

      return (
        <col
          key={i}
          style={{
            width: calculatedWidth
          }}
          {...h}
        />
      );
    });
  }, [headerGroups]);

  // eslint-disable-next-line no-unused-vars
  const { isLoading, disableSortby, ...rest } = props;

  const showEmptyState = emptyState && !isLoading && rows.length === 0;

  return (
    <div {...rest}>
      {headerContent}
      {!showEmptyState ? (
        <>
          <DataTableHorizontalScroll>
            <StyledTable {...getTableProps()} style={{ minWidth: '650px' }}>
              <Header
                enableBulkSelection={enableBulkSelection}
                isAllSelected={isAllSelected}
                headerGroups={headerGroups}
                setAllSelectedState={state =>
                  setRowSelectedState(rowIds, state)
                }
              />
              <colgroup>
                {enableBulkSelection && <col className="bulk-selection-col" />}
                {colGroups}
              </colgroup>
              <tbody {...getTableBodyProps()}>
                <TableRows
                  rows={rows}
                  prepareRow={prepareRow}
                  setRowSelectedState={setRowSelectedState}
                  enableBulkSelection={enableBulkSelection}
                  selectedRowIds={selectedRowIds}
                />
              </tbody>
            </StyledTable>
          </DataTableHorizontalScroll>
          {footerContent}
        </>
      ) : (
        <>{emptyState}</>
      )}
    </div>
  );
};

const StyledTable = styled(Table)`
  table-layout: fixed;

  colgroup col {
    &.bulk-selection-col {
      width: 3rem;
    }
  }

  thead {
    th {
      border: none;
      word-wrap: normal;
    }

    tr.data-table-header th {
      padding: 0;
      font-weight: normal;
      z-index: 2;
    }
  }

  thead tr:not(.data-table-header) th .sortable:hover {
    color: var(--gray);
  }

  tbody td {
    vertical-align: middle;
  }
`;

export default withLoadingOverlay({
  Component: TableWithHeader,
  minHeight: '6rem',
  backgroundColor: 'var(--white)'
});
