import * as SentryReact from '@sentry/react';
import { ENVIRONMENT } from '@/config/settings';

export class Sentry {
  init() {
    SentryReact.init({
      dsn: 'https://dd21eefbfc45dd9d5668c731e3d372c4@o84128.ingest.us.sentry.io/4508103954857984',
      environment: ENVIRONMENT,
      integrations: [
        SentryReact.browserTracingIntegration(),
      //  SentryReact.replayIntegration()
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      //replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
  identify(user) {
    SentryReact.setUser({ id: user.userId });
  }
  trackError(error) {
    SentryReact.captureException(error);
  }
}
