import reducers from '@/reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import Cache from '@/cache/Cache';
import { ENVIRONMENT } from '@/config/settings';

const middleware = [ReduxThunk];

if (ENVIRONMENT === 'development') {
  import('redux-logger').then(({ createLogger }) => {
    const logger = createLogger({ collapsed: true });
    middleware.push(logger);
  });
}

const configureStore = onComplete => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store, null, onComplete);

  return { store, persistor, cache: new Cache() };
};

export default configureStore;
