import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import analytics from '@/analytics';

import { setJoinStatus } from '@/actions/joinSchoolStepper';
import joinStatuses from '@/constants/joinStatuses';
import { deselectSchool } from '@/actions/onboarding';
import { Card, Button, Icon, toaster } from '@/components/common';
import { useNavigate } from 'react-router';
import { Api } from '@/api';
import { colors } from '@/styles';
import { SchoolIcon, FilledCircle } from '@/assets/icons';
import useLoadUserContext from '@/hooks/useLoadUserContext';
import tracking from '@/analytics/tracking';

const SchoolJoin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const joinSchoolStepper = useSelector(s => s.joinSchoolStepper);
  const userId = useSelector(s => s.user.userId);
  const { fields, joinStatus } = joinSchoolStepper;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { loadUserContext } = useLoadUserContext();

  const searchAgain = useCallback(
    (name, deselect) => {
      if (deselect) dispatch(deselectSchool());
      navigate('/schools', { state: name });
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (!fields.name) {
      searchAgain();
    }
  }, [fields, searchAgain]);

  const handleJoinSchool = async () => {
    dispatch(setJoinStatus(joinStatuses.loading));
    setIsSubmitting(true);

    try {
      await Api.joinSchool(fields.schoolId, userId);
      dispatch(setJoinStatus(joinStatuses.joinedUnverified));
      // Kept the duplicate tracking here, need to check if the `analytics.track` one is needed. Intercom maybe?
      tracking.track('School Joined');
      analytics.track('School Joined', {
        category: 'School',
        'School ID': fields.schoolId
      });
      await loadUserContext(fields.schoolId);
      toaster.success("You've joined this school!");
      navigate(`/${fields.schoolId}/pending`);
    } catch (e) {
      dispatch(setJoinStatus(joinStatuses.failed, e));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <div className="text-center mb-4">
        <h1>You're about to join</h1>
      </div>

      <Card>
        <Card.Body>
          <div className="d-flex align-items-center mb-4">
            <FilledCircle
              className="mr-3"
              size={5}
              color={colors.purpleLighter}>
              <SchoolIcon name={fields.name} size="3.5rem" />
            </FilledCircle>
            <div className="flex-fill">
              <h2>{fields.name}</h2>
              <h4 className="subtitle">
                {[fields.stateProvinceCounty, fields.city]
                  .filter(item => item)
                  .join(', ')}
              </h4>
            </div>
          </div>
          <Button
            onClick={handleJoinSchool}
            color="primary"
            loading={joinStatus === joinStatuses.loading || isSubmitting}
            block>
            Join
          </Button>
        </Card.Body>
      </Card>

      <Button
        color="link"
        onClick={() => searchAgain(fields.name, true)}
        className="mt-3">
        <Icon name="arrowLeft" color="primary" />
        Search again
      </Button>
    </>
  );
};

export default SchoolJoin;
