/* eslint-disable no-unused-vars */
import { ENVIRONMENT } from '@/config/settings';
import hash from 'object-hash';
import helper from './searchObjectHelper';

class Cache {
  constructor(store = null, defaultCacheLengthMinutes = 1440) {
    this._cache = store ?? new Map();
    this._defaultCacheLengthMinutes = defaultCacheLengthMinutes;
    this.restoreFromSessionStorage();
    this.debugLog({
      func: 'constructor',
      cache: this._cache,
      defaultCacheLengthMinutes: this._defaultCacheLengthMinutes
    });
  }

  getExpiryDate = ttlMinutes => {
    const now = new Date();
    now.setMinutes(
      now.getMinutes() + (ttlMinutes || this._defaultCacheLengthMinutes)
    );
    this.debugLog({
      ttlMinutes,
      defaultCacheLengthMinutes: this._defaultCacheLengthMinutes,
      now,
      nowString: now.toUTCString()
    });
    return now.toUTCString();
  };

  getEntry = ({ key, value, ttlMinutes = 0, typename = null }) => ({
    key,
    value,
    expiryDate: this.getExpiryDate(ttlMinutes),
    setDate: new Date().toUTCString(),
    typename
  });

  set = ({
    key,
    value = null,
    ttlMinutes = 0,
    typename = null,
    isUseSessionStorage = false
  }) => {
    if (ttlMinutes <= 0) return value;

    const entry = this.getEntry({ key, value, ttlMinutes, typename });
    this._cache.set(key, entry);
    isUseSessionStorage && sessionStorage.setItem(key, JSON.stringify(entry));
    this.debugLog({
      func: 'set',
      key,
      value,
      ttlMinutes: ttlMinutes || this.defaultCacheLengthMinutes,
      entry
    });
    return value;
  };

  get = ({ key }) => {
    try {
      let entry = this._cache.get(key);
      let isHydrate = false;
      if (!entry) {
        const sessionStorageCacheBody = sessionStorage.getItem(key);
        entry = sessionStorageCacheBody
          ? JSON.parse(sessionStorageCacheBody)
          : null;
        isHydrate = true;
      }
      const isExpired = entry?.expiryDate
        ? new Date(entry.expiryDate) < new Date()
        : true;
      this.debugLog({
        func: 'getEntry',
        state: entry && !isExpired ? 'Cache Hit' : 'Cache Miss',
        key,
        entry
      });
      if (!entry) {
        return null;
      }
      if (isExpired) {
        this._cache.delete(key);
        sessionStorage.removeItem(key);
        return null;
      }
      if (isHydrate) {
        this._cache.set(key, entry);
      }
      return entry.value;
    } catch (e) {
      console.error('Cache Error!', e);
      return null;
    }
  };

  remove = ({ key }) => {
    this._cache.delete(key);
    sessionStorage.removeItem(key);
  };

  clearType = ({ typename }) => {
    this.debugLog({
      func: 'clearEntity',
      typename,
      cache: this._cache
    });
    this._cache.forEach((entry, key) => {
      this.debugLog({
        func: 'clearEntity - entry',
        typename,
        entry,
        key,
        isClearable: entry.typename === typename
      });

      if (entry.typename !== typename) return;

      this._cache.delete(key);
      sessionStorage.removeItem(key);
    });
  };

  getOrAddAsync = async ({
    key,
    callback,
    ttlMinutes = 0,
    properties = null,
    typename = null,
    isUseSessionStorage = false,
    isForceRefetch = false
  }) => {
    if (!isUseSessionStorage) {
      sessionStorage.removeItem(key);
    }
    key = key + (!properties ? '' : `:${hash(properties)}`);
    let value = !isForceRefetch ? this.get({ key }) : null;
    this.debugLog({
      func: 'getOrAddAsync',
      status: value
        ? 'Cache Hit'
        : isForceRefetch
        ? 'Force Miss'
        : 'Cache Miss',
      key,
      value,
      ttlMinutes: ttlMinutes || this.defaultCacheLengthMinutes,
      properties,
      typename
    });
    if (value) {
      return value;
    }
    value = await callback();
    this.debugLog({
      func: 'getOrAddAsync',
      status: 'Fetched',
      key,
      value,
      ttlMinutes: ttlMinutes || this.defaultCacheLengthMinutes,
      properties,
      typename
    });
    this.set({ key, value, ttlMinutes, typename, isUseSessionStorage });
    return value;
  };

  getOrAdd = ({
    key,
    callback,
    ttlMinutes = 0,
    properties = null,
    typename = null,
    isUseSessionStorage = false,
    isForceRefetch = false
  }) => {
    if (!isUseSessionStorage) {
      sessionStorage.removeItem(key);
    }
    key = key + (!properties ? '' : `:${hash(properties)}`);
    let value = !isForceRefetch ? this.get({ key }) : null;
    this.debugLog({
      func: 'getOrAdd',
      status: value
        ? 'Cache Hit'
        : isForceRefetch
        ? 'Force Miss'
        : 'Cache Miss',
      key,
      value,
      ttlMinutes: ttlMinutes || this.defaultCacheLengthMinutes,
      properties,
      typename
    });
    if (value) {
      if (!isUseSessionStorage) {
        sessionStorage.removeItem(key);
      }
      return value;
    }
    value = callback();
    this.debugLog({
      func: 'getOrAdd',
      status: 'Fetched',
      key,
      value,
      ttlMinutes: ttlMinutes || this.defaultCacheLengthMinutes,
      properties,
      typename
    });
    this.set({ key, value, ttlMinutes, typename, isUseSessionStorage });
    return value;
  };

  clear = () => {
    const entries = [];
    this._cache.forEach((entry, key) => {
      entries.push({ key, entry });
      sessionStorage.removeItem(key);
    });

    this.debugLog({
      func: 'clear',
      entries
    });

    sessionStorage.clear();
    this._cache.clear();
  };

  debugLog = (props = {}) => {
    if (ENVIRONMENT === 'prod') return;
    console.table('Cache Log', props);
  };

  getEntries = ({ filter = (entry, key) => true }) => {
    const entries = [];
    this.debugLog(this._cache);
    this._cache.forEach((entry, key) => {
      const validatedEntry = this.validateEntry({ entry, key });
      if (validatedEntry && filter(entry, key)) {
        entries.push({ key, entry });
      }
    });
    return entries;
  };

  removeEntries = ({ entryKeys = [], filter = null }) => {
    if (!entryKeys?.length) return;
    this._cache.forEach((entry, key) => {
      if (entryKeys.includes(key) || (!!filter && filter(entry, key))) {
        sessionStorage.removeItem(key);
        this._cache.delete(key);
      }
    });
  };

  validateEntry = ({ key, entry }) => {
    const isExpired = entry?.expiryDate
      ? new Date(entry.expiryDate) < new Date()
      : true;
    this.debugLog({ func: 'validateEntry', key, entry, isExpired });
    if (!entry) {
      return null;
    }
    if (isExpired) {
      this._cache.delete(key);
      sessionStorage.removeItem(key);
      return null;
    }
    return entry;
  };

  search = ({ typename, filter }) => {
    const entries = this.getEntries({
      filter: (entry, key) => entry.typename === typename
    });

    this.debugLog({ func: 'search', typename, filter, entries });

    if (!entries?.length) return [];

    const results = helper.find({
      obj: entries,
      filter,
      sourceFilter: obj => (!!obj?.entry ? obj : null)
    });

    this.debugLog({ func: 'search', status: 'typeMatched', results });

    return results;
  };

  restoreFromSessionStorage = () => {
    this.debugLog({ func: 'restoreFromSessionStorage' });
    for (let i = 0; i < sessionStorage.length; i++) {
      try {
        const key = sessionStorage.key(i);
        const entry = JSON.parse(sessionStorage.getItem(key));

        if (entry?.value !== undefined && entry?.value !== null) {
          this.debugLog({ func: 'restoreItem', entryVal: entry?.value, key });
          this._cache.set(key, entry);
        }
      } catch (e) {
        console.error(`Cache Error! key: ${i}`, e);
      }
    }
  };

  bustTypeCache = ({ typenames = [] }) => {
    typenames.forEach(typename => {
      this.debugLog({ func: 'bustTypeCache', typename });
      this.clearType({ typename });
    });
  };
}

export default Cache;
