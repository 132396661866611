import styled from 'styled-components';
import Loading from '@/components/common/Loading';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: 0.75;
  ${zIndex => zIndex};
  pointer-events: none;
`;

const RelativeContainer = styled.div`
  position: ${({ isloading }) => (isloading ? 'relative' : 'initial')};
  min-height: ${({ minHeight }) => minHeight};
`;

export const withLoadingOverlay =
  ({
    Component,
    minHeight = 0,
    backgroundColor = 'var(--white)',
    loadingZIndex = 999
  }) =>
  props =>
    (
      <RelativeContainer isloading={!!props?.isLoading} minHeight={minHeight}>
        {!!props?.isLoading && (
          <LoadingOverlay
            zIndex={loadingZIndex}
            backgroundColor={backgroundColor}>
            <Loading />
          </LoadingOverlay>
        )}
        <Component {...props} />
      </RelativeContainer>
    );

export default withLoadingOverlay;
