import withLoadingOverlay from '@/hocs/withLoadingOverlay';

import { Form as ReactstrapForm, FormGroup, FormText } from 'reactstrap';
import styled from 'styled-components';

const FormWithLoading = withLoadingOverlay({ Component: ReactstrapForm });
const Form = ({ loading, ...props }) => (
  <FormWithLoading isLoading={loading} {...props} />
);

Form.Group = FormGroup;
Form.Buttons = styled.div`
  text-align: right;
  margin-top: 1rem;
`;
Form.GroupText = FormText;

export default Form;
