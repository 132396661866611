import { Icon } from '@/components/common';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import { useEffect, useRef, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styled from 'styled-components';

const StyledInputGroupText = styled(InputGroupText)`
  padding: 0 0.5rem;
`;

const HoverIcon = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 99;
  cursor: pointer;

  color: var(--gray);
  &:hover {
    color: var(--gray-dark);
  }
`;
const CloseSVG = props => (
  <HoverIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6L6 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </HoverIcon>
);

const SearchBox = ({
  onChange = () => {},
  onTypingChange = () => {},
  placeholder = 'Search',
  defaultValue = '',
  className,
  ...props
}) => {
  const changeRefs = useRef();
  changeRefs.current = { onChange, onTypingChange };
  const [value, setValue] = useState(defaultValue);

  const debouncedValue = useDebouncedValue(value, 500);
  useEffect(() => {
    changeRefs.current.onChange(debouncedValue);
  }, [debouncedValue]);
  useEffect(() => {
    changeRefs.current.onTypingChange({
      isTyping: value !== debouncedValue,
      rawText: value,
      debouncedText: debouncedValue
    });
  }, [debouncedValue, value]);

  return (
    <InputGroup {...{ className }}>
      <InputGroupAddon addonType="prepend">
        <StyledInputGroupText>
          <Icon name="search" />
        </StyledInputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        {...props}
      />
      {value.length > 0 && <CloseSVG onClick={() => setValue('')} />}
    </InputGroup>
  );
};

export default SearchBox;
