import { ENVIRONMENT } from '@/config/settings';
import ReactGA from 'react-ga';

export class GoogleAnalytics {
  init() {
    if (ENVIRONMENT === 'prod') {
      ReactGA.initialize('UA-90280045-6');
    } else {
      ReactGA.initialize('UA-90280045-7', {
        debug: true
      });
      ReactGA.ga('set', 'sendHitTask', null);
    }
  }

  identify(user) {
    if (user.schoolId) {
      ReactGA.ga('set', 'dimension3', user.schoolId);
    }

    if (user.userRole) {
      ReactGA.ga('set', 'dimension1', user.userRole);
    }
  }

  page(location) {
    ReactGA.set({ location });
    ReactGA.pageview(location);
  }

  track(event, data) {
    ReactGA.event({
      category: data && data.category ? data.category : 'All',
      action: event,
      label: data && data.label ? data.label : undefined,
      value: data && data.value ? data.value : undefined
    });
  }
}
