import { Input, Icon, Form } from '@/components/common';
import Container from '@/components/Container';
import { FilledCircle, SmallCard } from '@/components/dls';
import GroupedItemList from '@/components/GroupedItemList';
import CardPlaceholder from '@/components/placeholders/CardPlaceholder';
import fuzzysearch from 'fuzzysearch';
import pluralizeString from '@/helpers/pluralizeString';
import React, { Component, useMemo } from 'react';
import Placeholder from 'react-placeholder';
import styled from 'styled-components';

const SearchResults = ({
  searchBy,
  itemTitle,
  items,
  renderItem,
  getGroupByValue,
  noFilters,
  loadMore,
  hasMore,
  total,
  sortGroups,
  searchInputText
}) => {
  const matching = useMemo(
    () =>
      items
        ? noFilters
          ? items
          : items.filter(item =>
              fuzzysearch(
                searchInputText.toLowerCase(),
                (searchBy(item) || '').toLowerCase()
              )
            )
        : [],
    [items, noFilters, searchBy, searchInputText]
  );

  const count = total || matching.length;
  const title = pluralizeString(itemTitle || 'school', count);

  if (!searchInputText.length) {
    return <ResultsWrapper></ResultsWrapper>;
  }

  return (
    <ResultsWrapper>
      {count > 0 && (
        <ResultsContainer>
          <h4>
            {count.toLocaleString()} {title}
          </h4>
        </ResultsContainer>
      )}
      <GroupedItemList
        items={matching}
        renderItem={renderItem}
        getGroupByValue={getGroupByValue}
        loadMore={loadMore}
        hasMore={hasMore}
        sortGroups={sortGroups}
      />
    </ResultsWrapper>
  );
};

class SearchStep extends Component {
  constructor(props) {
    console.log('searchstep', props);
    super(props);
    this.state = {
      searchInputText: props.searchInputText || ''
    };
  }

  isControlledInput = () => {
    const { onSearchInputChange } = this.props;
    return !!onSearchInputChange && typeof onSearchInputChange === 'function';
  };

  onSearchInputChange = e => {
    if (this.isControlledInput()) {
      this.props.onSearchInputChange(e.target.value);
    } else this.setState({ searchInputText: e.target.value });
  };

  renderSearch = () => (
    <Form.Group>
      <Input
        type="text"
        onChange={this.onSearchInputChange}
        value={
          this.isControlledInput()
            ? this.props.searchInputText
            : this.state.searchInputText
        }
        size="large"
        placeholder={this.props.searchText || "Enter your school's name"}
        autoFocus
      />
    </Form.Group>
  );

  handleAddClick = () => {
    const searchInputText = this.isControlledInput()
      ? this.props.searchInputText
      : this.state.searchInputText;
    this.props.onAdd(searchInputText || '');
  };

  render() {
    const {
      addText,
      loading,
      addCardReplacement,
      hideAddCardIfNoSearchQuery,
      searchBy,
      itemTitle,
      items,
      renderItem,
      getGroupByValue,
      noFilters,
      loadMore,
      hasMore,
      total,
      sortGroups
    } = this.props;

    const searchInputText = this.isControlledInput()
      ? this.props.searchInputText || ''
      : this.state.searchInputText || '';

    const addButtonText =
      typeof addText === 'function' ? addText(searchInputText) : addText;

    const showAddCard = hideAddCardIfNoSearchQuery ? !!searchInputText : true;

    const addCard = addCardReplacement || (
      <SmallCard
        title={addButtonText}
        leftComponent={
          <FilledCircle color="grayLighter" size={3.5}>
            <Icon name="add" />
          </FilledCircle>
        }
        onClick={this.handleAddClick}
      />
    );
    return (
      <React.Fragment>
        {this.renderSearch()}
        {['/schools', '/schools/'].includes(window.location.pathname) ? (
          <Tandcs>
            By continuing, you're agreeing to our{' '}
            <a
              href="https://eedi.com/legal/terms-of-service"
              target="_blank"
              rel="noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://eedi.com/legal/privacy"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
          </Tandcs>
        ) : null}
        {showAddCard && addCard}
        {loading && searchInputText ? (
          <Placeholder customPlaceholder={<SearchStep.Placeholder />} />
        ) : (
          <SearchResults
            searchInputText={searchInputText}
            searchBy={searchBy}
            itemTitle={itemTitle}
            items={items}
            renderItem={renderItem}
            getGroupByValue={getGroupByValue}
            noFilters={noFilters}
            loadMore={loadMore}
            hasMore={hasMore}
            total={total}
            sortGroups={sortGroups}
          />
        )}
      </React.Fragment>
    );
  }
}

SearchStep.Placeholder = () => (
  <ResultsPlaceholder>
    <CardPlaceholder cards={4} />
  </ResultsPlaceholder>
);

const ResultsPlaceholder = styled(Container)`
  margin-top: 0.5rem;
  margin-bottom: 6rem;
`;

const ResultsContainer = styled(Container)`
  padding: 2rem 0rem 0.25rem 0rem;
`;

const ResultsWrapper = styled(Container)`
  margin-bottom: 6rem;
`;

const Tandcs = styled.div`
  color: var(--gray);
  font-size: 0.9rem;
  text-align: center;
  margin: 0 0 1rem 0;
  a {
    color: var(--gray);
    text-decoration: underline;
  }
`;

export default SearchStep;
